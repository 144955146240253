import appLogo from "../assets/images/app-logo.png";
import errNoImg from "../assets/images/image-not-load.png";
import logoNew from "../assets/images/logo_new.png";
import warningAlert from "../assets/images/warning.jpg";

import appleStore from "../assets/images/apple-store.png";
import buttonWeb from "../assets/images/button-web.png";
import dbdRegistered from "../assets/images/DBD-Registered.png";
import playStore from "../assets/images/google-play-store.png";
import iPhone11All from "../assets/images/iPhone11-all.jpg";
import iPhoneXAll from "../assets/images/iPhoneX-all.jpg";
import iPhoneXRAll from "../assets/images/iPhoneXR-all.jpg";
import iPhoneXSAll from "../assets/images/iPhoneXS-all.jpg";
import lineLogo from "../assets/images/line.png";
import logoHorizontal from "../assets/images/logo_horizontal_no_bg.png";
import logoNonbg from "../assets/images/Logo_non_bg.png";
import facebookLogo from "../assets/images/logofb.png";
import phoneCall from "../assets/images/phone-call.png";

import footer from "../assets/images/footer.png";
import footerInstallment from "../assets/images/footer_installment.webp";
import footerRefinance from "../assets/images/footer_refinance.webp";
import lineApp from "../assets/images/Line_app.webp";
import cityBg from "../assets/images/cityBG.webp"
import mLineOa from "../assets/images/m_lineoa.webp"
/* Campaign */
import campaignFlimClick from "../assets/images/campaignflimclick.png";
import campaignUfiendClick from "../assets/images/campaignufriendclick.png";


/* web */
import UFW1_1 from "../assets/images/landingpage/installment/web/UF-W1-1.webp";
import UFW1_10 from "../assets/images/landingpage/installment/web/UF-W1-10.webp";
import UFW1_11 from "../assets/images/landingpage/installment/web/UF-W1-11.webp";
import UFW1_2 from "../assets/images/landingpage/installment/web/UF-W1-2.webp";
import UFW1_3 from "../assets/images/landingpage/installment/web/UF-W1-3.webp";
import UFW1_4 from "../assets/images/landingpage/installment/web/UF-W1-4.webp";
import UFW1_5 from "../assets/images/landingpage/installment/web/UF-W1-5.webp";
import UFW1_6 from "../assets/images/landingpage/installment/web/UF-W1-6.webp";
import UFW1_7 from "../assets/images/landingpage/installment/web/UF-W1-7.webp";
import UFW1_8 from "../assets/images/landingpage/installment/web/UF-W1-8.webp";
import UFW1_9 from "../assets/images/landingpage/installment/web/UF-W1-9.webp";
// import UFW1_12 from "../assets/images/landingpage/installment/web/UF-W1-12.webp";
import UFW1_13 from "../assets/images/landingpage/installment/web/UF-W1-13.webp";
import UFW1_14 from "../assets/images/landingpage/installment/web/UF-W1-14.webp";


import UFWEB2_1 from "../assets/images/landingpage/refinance/web/UF-Web2-1.webp";
import UFWEB2_10 from "../assets/images/landingpage/refinance/web/UF-Web2-10.webp";
import UFWEB2_11 from "../assets/images/landingpage/refinance/web/UF-Web2-11.webp";
import UFWEB2_12 from "../assets/images/landingpage/refinance/web/UF-Web2-12.webp";
import UFWEB2_2 from "../assets/images/landingpage/refinance/web/UF-Web2-2.webp";
import UFWEB2_3 from "../assets/images/landingpage/refinance/web/UF-Web2-3.webp";
import UFWEB2_4 from "../assets/images/landingpage/refinance/web/UF-Web2-4.webp";
import UFWEB2_5 from "../assets/images/landingpage/refinance/web/UF-Web2-5.webp";
import UFWEB2_6 from "../assets/images/landingpage/refinance/web/UF-Web2-6.webp";
import UFWEB2_7 from "../assets/images/landingpage/refinance/web/UF-Web2-7.webp";
import UFWEB2_8 from "../assets/images/landingpage/refinance/web/UF-Web2-8.webp";
import UFWEB2_9 from "../assets/images/landingpage/refinance/web/UF-Web2-9.webp";



/* mobile */
import MUF1_1 from "../assets/images/landingpage/installment/mobile/M-UF1-1.webp";
import MUF1_10 from "../assets/images/landingpage/installment/mobile/M-UF1-10.webp";
import MUF1_11 from "../assets/images/landingpage/installment/mobile/M-UF1-11.webp";
import MUF1_12 from "../assets/images/landingpage/installment/mobile/M-UF1-12.webp";
import MUF1_13 from "../assets/images/landingpage/installment/mobile/M-UF1-13.webp";
import MUF1_2 from "../assets/images/landingpage/installment/mobile/M-UF1-2.webp";
import MUF1_3 from "../assets/images/landingpage/installment/mobile/M-UF1-3.webp";
import MUF1_4 from "../assets/images/landingpage/installment/mobile/M-UF1-4.webp";
import MUF1_5 from "../assets/images/landingpage/installment/mobile/M-UF1-5.webp";
import MUF1_6 from "../assets/images/landingpage/installment/mobile/M-UF1-6.webp";
import MUF1_7 from "../assets/images/landingpage/installment/mobile/M-UF1-7.webp";
import MUF1_8 from "../assets/images/landingpage/installment/mobile/M-UF1-8.webp";
import MUF1_9 from "../assets/images/landingpage/installment/mobile/M-UF1-9.webp";

import MUF2_1 from "../assets/images/landingpage/refinance/mobile/M-UF2-1.webp";
import MUF2_2 from "../assets/images/landingpage/refinance/mobile/M-UF2-2.webp";
import MUF2_3 from "../assets/images/landingpage/refinance/mobile/M-UF2-3.webp";
import MUF2_4 from "../assets/images/landingpage/refinance/mobile/M-UF2-4.webp";
import MUF2_5 from "../assets/images/landingpage/refinance/mobile/M-UF2-5.webp";
import MUF2_6 from "../assets/images/landingpage/refinance/mobile/M-UF2-6.webp";
import MUF2_7 from "../assets/images/landingpage/refinance/mobile/M-UF2-7.webp";
import MUF2_8 from "../assets/images/landingpage/refinance/mobile/M-UF2-8.webp";
import MUF2_9 from "../assets/images/landingpage/refinance/mobile/M-UF2-9.webp";



// /* web */
// import UFW1_1 from "../assets/images/landingpage/installment/web/UF-W1-1.jpg";
// import UFW1_2 from "../assets/images/landingpage/installment/web/UF-W1-2.png";
// import UFW1_3 from "../assets/images/landingpage/installment/web/UF-W1-3.png";
// import UFW1_4 from "../assets/images/landingpage/installment/web/UF-W1-4.png";
// import UFW1_5 from "../assets/images/landingpage/installment/web/UF-W1-5.png";
// import UFW1_6 from "../assets/images/landingpage/installment/web/UF-W1-6.png";
// import UFW1_7 from "../assets/images/landingpage/installment/web/UF-W1-7.png";
// import UFW1_8 from "../assets/images/landingpage/installment/web/UF-W1-8.png";
// import UFW1_9 from "../assets/images/landingpage/installment/web/UF-W1-9.png";
// import UFW1_10 from "../assets/images/landingpage/installment/web/UF-W1-10.png";
// import UFW1_11 from "../assets/images/landingpage/installment/web/UF-W1-11.png";
// // import UFW1_12 from "../assets/images/landingpage/installment/web/UF-W1-12.png";
// import UFW1_13 from "../assets/images/landingpage/installment/web/UF-W1-13.png";
// import UFW1_14 from "../assets/images/landingpage/installment/web/UF-W1-14.png";


// import UFWEB2_1 from "../assets/images/landingpage/refinance/web/UF-Web2-1.jpg";
// import UFWEB2_2 from "../assets/images/landingpage/refinance/web/UF-Web2-2.png";
// import UFWEB2_3 from "../assets/images/landingpage/refinance/web/UF-Web2-3.png";
// import UFWEB2_4 from "../assets/images/landingpage/refinance/web/UF-Web2-4.png";
// import UFWEB2_5 from "../assets/images/landingpage/refinance/web/UF-Web2-5.png";
// import UFWEB2_6 from "../assets/images/landingpage/refinance/web/UF-Web2-6.png";
// import UFWEB2_7 from "../assets/images/landingpage/refinance/web/UF-Web2-7.png";
// import UFWEB2_8 from "../assets/images/landingpage/refinance/web/UF-Web2-8.png";
// import UFWEB2_9 from "../assets/images/landingpage/refinance/web/UF-Web2-9.png";
// import UFWEB2_10 from "../assets/images/landingpage/refinance/web/UF-Web2-10.png";
// import UFWEB2_11 from "../assets/images/landingpage/refinance/web/UF-Web2-11.png";
// import UFWEB2_12 from "../assets/images/landingpage/refinance/web/UF-Web2-12.png";



// /* mobile */
// import MUF1_1 from "../assets/images/landingpage/installment/mobile/M-UF1-1.png";
// import MUF1_2 from "../assets/images/landingpage/installment/mobile/M-UF1-2.png";
// import MUF1_3 from "../assets/images/landingpage/installment/mobile/M-UF1-3.png";
// import MUF1_4 from "../assets/images/landingpage/installment/mobile/M-UF1-4.png";
// import MUF1_5 from "../assets/images/landingpage/installment/mobile/M-UF1-5.png";
// import MUF1_6 from "../assets/images/landingpage/installment/mobile/M-UF1-6.png";
// import MUF1_7 from "../assets/images/landingpage/installment/mobile/M-UF1-7.png";
// import MUF1_8 from "../assets/images/landingpage/installment/mobile/M-UF1-8.png";
// import MUF1_9 from "../assets/images/landingpage/installment/mobile/M-UF1-9.png";
// import MUF1_10 from "../assets/images/landingpage/installment/mobile/M-UF1-10.png";
// import MUF1_11 from "../assets/images/landingpage/installment/mobile/M-UF1-11.png";
// import MUF1_12 from "../assets/images/landingpage/installment/mobile/M-UF1-12.png";
// import MUF1_13 from "../assets/images/landingpage/installment/mobile/M-UF1-13.png";

// import MUF2_1 from "../assets/images/landingpage/refinance/mobile/M-UF2-1.png";
// import MUF2_2 from "../assets/images/landingpage/refinance/mobile/M-UF2-2.png";
// import MUF2_3 from "../assets/images/landingpage/refinance/mobile/M-UF2-3.png";
// import MUF2_4 from "../assets/images/landingpage/refinance/mobile/M-UF2-4.png";
// import MUF2_5 from "../assets/images/landingpage/refinance/mobile/M-UF2-5.png";
// import MUF2_6 from "../assets/images/landingpage/refinance/mobile/M-UF2-6.png";
// import MUF2_7 from "../assets/images/landingpage/refinance/mobile/M-UF2-7.png";
// import MUF2_8 from "../assets/images/landingpage/refinance/mobile/M-UF2-8.png";
// import MUF2_9 from "../assets/images/landingpage/refinance/mobile/M-UF2-9.png";


export const imgs = {
  errNoImg: errNoImg,
  warningAlert: warningAlert,
  appLogo: appLogo,
  newLogo: logoNew,
  appleStore: appleStore,
  playStore: playStore,
  dbdRegistered: dbdRegistered,
  buttonWeb: buttonWeb,
  lineLogo: lineLogo,
  facebookLogo: facebookLogo,
  phoneCall: phoneCall,
  iPhoneXAll: iPhoneXAll,
  iPhoneXSAll: iPhoneXSAll,
  iPhoneXRAll: iPhoneXRAll,
  iPhone11All: iPhone11All,
  campaignUfiendClick: campaignUfiendClick,
  campaignFlimClick: campaignFlimClick,
  logoNonbg: logoNonbg,
  logoHorizontal: logoHorizontal,
  footerInstallment: footerInstallment,
  footerRefinance: footerRefinance,
  footer: footer,
  lineApp: lineApp,
  cityBg: cityBg,
  mLineOa:mLineOa
};

export const imagesRefinace = {
  UFWEB2_1: UFWEB2_1,
  UFWEB2_2: UFWEB2_2,
  UFWEB2_3: UFWEB2_3,
  UFWEB2_4: UFWEB2_4,
  UFWEB2_5: UFWEB2_5,
  UFWEB2_6: UFWEB2_6,
  UFWEB2_7: UFWEB2_7,
  UFWEB2_8: UFWEB2_8,
  UFWEB2_9: UFWEB2_9,
  UFWEB2_10: UFWEB2_10,
  UFWEB2_11: UFWEB2_11,
  UFWEB2_12: UFWEB2_12,


  MUF2_1: MUF2_1,
  MUF2_2: MUF2_2,
  MUF2_3: MUF2_3,
  MUF2_4: MUF2_4,
  MUF2_5: MUF2_5,
  MUF2_6: MUF2_6,
  MUF2_7: MUF2_7,
  MUF2_8: MUF2_8,
  MUF2_9: MUF2_9,
}

export const imagesInstallment = {
  UFW1_1: UFW1_1,
  UFW1_2: UFW1_2,
  UFW1_3: UFW1_3,
  UFW1_4: UFW1_4,
  UFW1_5: UFW1_5,
  UFW1_6: UFW1_6,
  UFW1_7: UFW1_7,
  UFW1_8: UFW1_8,
  UFW1_9: UFW1_9,
  UFW1_10: UFW1_10,
  UFW1_11: UFW1_11,
  // UFW1_12: UFW1_12,
  UFW1_13: UFW1_13,
  UFW1_14: UFW1_14,


  MUF1_1: MUF1_1,
  MUF1_2: MUF1_2,
  MUF1_3: MUF1_3,
  MUF1_4: MUF1_4,
  MUF1_5: MUF1_5,
  MUF1_6: MUF1_6,
  MUF1_7: MUF1_7,
  MUF1_8: MUF1_8,
  MUF1_9: MUF1_9,
  MUF1_10: MUF1_10,
  MUF1_11: MUF1_11,
  MUF1_12: MUF1_12,
  MUF1_13: MUF1_13,
}



export const onImgError = ({ currentTarget }: any) => {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = imgs.errNoImg;
};

