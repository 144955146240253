import { Row, Col } from "react-bootstrap";
import _ from "lodash";
import * as Inf from "../interface/estimate.interfaces";
import ButtonCustom from "../../../../components/button/buttonCustom";

const EstimateOptionsGroup = (props: Inf.EstimateOptionsGroupProps) => {
  const RESPONSIVE = {
    xs: 12,
    sm: 6,
    lg: 4,
    xl: 4,
    xxl: 4,
  };

  const { data, multiple, onSelect, selectedCode, subKeyLength = 0, estimateValue, setEstimateValue, subData } = props;

  const getHeaderText = (newItemCode: any, subData: any) => {
    // สร้าง array ว่างเพื่อเก็บผลลัพธ์ตามลำดับของ data
    const orderedResults = new Array(data.length).fill(null);
    // วนลูปผ่าน newItemCode และจัดเก็บ name ตามลำดับใน data
    newItemCode.forEach((code: any) => {
      subData.forEach((item: any, index: number) => {
        const found = item.sub_key.find((sub: any) => sub.code === code);
        if (found) {
          orderedResults[index] = found.name;
        }
      });
    });

    // กรอง null ออกและรวม string ด้วย "/"
    const text = orderedResults.filter((name) => name !== null).join("/");
    return text;
  };

  const handleSelect = (opt: Inf.SubKey) => {
    let newItemCode: string[] = [];
    let newItemName: string = "";
    let expand = true;

    const hasSelected = _.includes(selectedCode, opt?.code);

    if (multiple) {
      newItemCode = hasSelected
        ? _.filter(selectedCode, (optCode) => optCode !== opt?.code)
        : _.union(selectedCode, [opt.code]);
      newItemName = `มี ${newItemCode.length} ข้อ`;

      // set expand
      expand = false;
    } else if (subKeyLength > 1) {
      // มี subkey มากกว่า 1
      const temp = [...selectedCode];

      const hasSelected = _.includes(selectedCode, opt?.code);
      const prefix = _.slice(opt?.code, 0, 3).join("");
      const index = _.findIndex(selectedCode, (item) => item.includes(prefix));

      // found
      if (index !== -1) {
        temp.splice(index, 1);
        newItemCode = hasSelected ? temp : [...temp, opt?.code];
        newItemName = getHeaderText(newItemCode, subData);
      } else {
        newItemCode = [...selectedCode, opt?.code];
        newItemName = newItemName = getHeaderText(newItemCode, subData);
      }

      // set expand
      if (newItemCode.length !== subKeyLength) expand = false;
    } else {
      newItemCode = hasSelected ? [] : [opt?.code];
      newItemName = hasSelected ? "" : opt?.name;
    }

    // set ข้อมูลสำหรับ api condtion-maxprice
    onSetEstimateValue(opt, hasSelected, multiple);
    
    onSelect({ code: newItemCode, name: newItemName, expand: expand });
  };

  const onSetEstimateValue = (opt: Inf.SubKey, hasSelected: boolean, multiple: boolean = false) => {
    let estValue: any[] = [];
    if (hasSelected) {
      estValue = filterByValue(opt);
    } else {
      if (multiple) {
        estValue = filterByValue(opt);
      } else {
        estValue = filterByPrefixCode(opt);
      }

      estValue.push({ code: opt.code, value: opt.value });
    }

    setEstimateValue(estValue);
  };

  const filterByValue = (opt: any) => {
    const estValue = estimateValue.filter((item: any) => {
      if (item.code !== opt?.code) {
        return item;
      }
    });
    return estValue;
  };

  const filterByPrefixCode = (opt: any) => {
    const estValue = estimateValue.filter((item: any) => {
      const prefix = _.slice(opt?.code, 0, 3).join("");
      if (!item?.code?.includes(prefix)) {
        return item;
      }
    });

    return estValue;
  };

  return (
    <Row>
      {_.map(data, (item: Inf.SubKey, index: number) => (
        <Col {...RESPONSIVE} className="pt-2">
          <ButtonCustom
            key={item?.code || index}
            className={_.includes(selectedCode, item?.code) ? "btn-estimate-selected" : `btn-estimate`}
            variant={"outlined"}
            onClick={() => handleSelect(item)}
            btnStyle={{
              fontWeight: "bold",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 16px !important",
            }}
            textButton={item?.name}
          />
        </Col>
      ))}
    </Row>
  );
};

export default EstimateOptionsGroup;
