import { styled } from "@mui/material/styles";

export const Container = styled(`div`)(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: "30px 170px",
  marginLeft: '0px !important',
  marginTop: '0px !important',

  [theme.breakpoints.down('xl')]: {
    padding: "0px 80px",
  },
  [theme.breakpoints.down('lg')]: {
    padding: "10px 80px",
  },
  [theme.breakpoints.down('md')]: {
    padding: "10px 40px",
  }
}));
