import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Row, Col } from 'react-bootstrap'

export const Container = styled(`div`)(({ theme }) => ({
    padding: "20px 200px",

    // ขนาด 1440px ขึ้นไป (XL)
    [theme.breakpoints.down(1440)]: {
        padding: "15px 150px",
    },

    // ขนาด 1280px - 1439px (LG)
    [theme.breakpoints.down(1280)]: {
        padding: "15px 100px",
    },

    // ขนาด 1024px - 1279px (MD)
    [theme.breakpoints.down(1024)]: {
        padding: "10px 80px",
    },

    // ขนาด 768px - 1023px (SM)
    [theme.breakpoints.down(768)]: {
        padding: "10px 50px",
    },

    // ขนาด 480px - 767px (XS)
    [theme.breakpoints.down(480)]: {
        padding: "5px 20px",
    },

    // ขนาดต่ำกว่า 360px (มือถือเล็ก)
    [theme.breakpoints.down(360)]: {
        padding: "5px 10px",
    },
}))

export const MarginRow = styled(Row)(() => ({
    marginTop: 8,

}))

export const CenterContainer = styled(Row)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))

export const TypeBox = styled(Box)(() => ({
    padding: "10px",
    margin: "10px",
    textAlign: "center",
    border: "1px solid #ccc",
    borderRadius: 10,
    position: "relative",
    width: "100%",
}));


export const ColType = styled(Col)`
    flex-basis: 33.3333%; /* ค่า default เท่ากับ lg={4} */
    max-width: 33.3333%;

    @media (max-width: 1400px) {
        flex-basis: 33.3333%; /* xxl={4} ปรับเป็น 6-columns */
        max-width: 33.3333%;
    }

    @media (max-width: 1024px) {
        flex-basis: 33.3333%;
        max-width: 33.3333%;
    }

    @media (max-width: 864px) {
        flex-basis: 100%;
        max-width: 100%;
    }

    @media (max-width: 768px) {
        flex-basis: 100%; /* sm={12} ปรับเต็ม */
        max-width: 100%;
    }
`;