import axios from 'axios';

export default class LarkService {
  private initDt: any;
  private readonly enableLark: boolean;
  private readonly urlLarkHook: string;

  constructor(topic: string, template: string) {
    this.urlLarkHook = process.env.REACT_APP_URI_BOT_LARK_GROUP || '';
    this.enableLark = process.env.REACT_APP_ENABLE_BOT_LARK === 'true' ? true : false;
    this.initDt = {
      msg_type: 'interactive',
      card: {
        config: { wide_screen_mode: true, enable_forward: true },
        header: {
          title: {
            tag: 'plain_text',
            content: `${'Register Web'} ${process.env['REACT_APP_WEB_VERSION']} || ${topic}` || 'JOB Monitor'
          },
          template: template || 'yellow'
        }
      }
    };
  }

  public send = async (message: any) => {
    try {
      if (!this.enableLark || !this.urlLarkHook) return;
      this.initDt['card'] = {
        ...this.initDt['card'],
        elements: [
          {
            tag: 'div',
            text: {
              content:
                `${message} \n\n TIMESTAMP: ${new Date().toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })}` ||
                null,
              tag: 'lark_md'
            }
          }
        ]
      };

      fetch(this.urlLarkHook, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.initDt),
        mode: "no-cors",
      })

      new Promise(resolve => setTimeout(resolve, 1000));
    } catch (error) {
      return;
    }
  };
}
