
export const routeName = {
  register: '/register',
  nearBranch: '/near-branch',
  campaignFilmFocus: '/campaign/film-focus',
  refinance: '/sp_turn1',
  installment: '/sp_leasing1',
  ufriendShoplink: '/shoplink',


  reDevTest: '/re-dev-test',
  inDevTest: '/in-dev-test',
};
