import axios from "axios";

export const apiUrl = process.env.REACT_APP_API

const instance = axios.create({
    baseURL: apiUrl,
    timeout: 100000,
    headers: {
        "Content-Type": "application/json",
    },
});

// ✅ Interceptor เพื่อเพิ่ม x-api-key ทุกครั้งที่ request ออกไป
instance.interceptors.request.use(
    function (config) {
        const apiKey = process.env.REACT_APP_API_KEY || "";
        if (apiKey) {
            config.headers["x-api-key"] = apiKey;
        }
        return config;
    },
    function (error) {
        console.error("❌ Request Interceptor Error:", error);
        return Promise.reject(error);
    }
);

export default class BaseAPIPartner {
    static api = instance;
}
