import { useCallback, useState } from "react";
import { Chip } from "@mui/material";
import _ from "lodash";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import AuthenApi from "../../../api/authen/authen.api";
import Loading from "../../../components/loading/loading";
import { CouponRes } from "../type-register";
import SubText from "../../../components/input/subText";

interface props {
  data: any;
  initStateErrorMessage: any;
  errorMessage: any;
  setErrorMessage: (value: any) => void;
  discountPeriod: any;
  discountTurn1?: any;
  discountTurn1Down?: any;
  coupon: CouponRes[];
  setCoupon: (value: any) => void;
}

export default function CouponInput(props: props) {
  const {
    data,
    initStateErrorMessage,
    errorMessage,
    setErrorMessage,
    discountPeriod,
    discountTurn1,
    discountTurn1Down,
    coupon,
    setCoupon,
  } = props;

  const [couponValue, setCouponValue] = useState<string>("");

  // Loading API
  const [checkCouponLoading, setCheckCouponLoading] = useState<boolean>(false);

  const checkCoupon = useCallback(() => {
    if (couponValue && data?.product?.code) {
      setCheckCouponLoading(true);
      const condition: any = {
        productCode: data?.product?.code,
        coupon: couponValue,
      };
      AuthenApi.checkCoupon(condition)
        .then((res) => {
          if (res.status === 200) {
            const { coupon_id, coupon_code, coupon_discount, coupon_label } = res?.data;
            setCoupon((prev: CouponRes[]) => [...prev, { coupon_id, coupon_code, coupon_discount, coupon_label }]);
            setCouponValue("");
          }
        })
        .catch((err) => {
          setCouponValue("");
          setErrorMessage({
            ...initStateErrorMessage,
            COUPON: err?.response?.data?.error?.code || "คูปองไม่สามารถใช้งานได้",
          });
        })
        .finally(() => {
          setCheckCouponLoading(false);
        });
    }
  }, [couponValue]);

  const handleInputCouponChange = (event: React.SyntheticEvent, newValue: string) => {
    const upperValue = _.toUpper(newValue);
    setCouponValue(upperValue);
  };

  const handleCouponKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && couponValue !== "") {
      if (!data?.product?.model || !data?.product?.storage || !data?.product?.color) {
        setErrorMessage({ ...initStateErrorMessage, COUPON: "ข้อมูลสินค้าไม่ถูกต้อง" });
      } else if (checkDuplicate()) {
        setErrorMessage({ ...initStateErrorMessage, COUPON: "ไม่สามารถใช้คูปองซ้ำได้" });
        setCouponValue("");
      } else {
        checkCoupon();
      }
    }
  };

  const handleDeleteCoupon = (value: string) => {
    const newSelectedCoupon = _.filter(coupon, (item) => item.coupon_label !== value);
    setCoupon(newSelectedCoupon);

    setErrorMessage({ ...initStateErrorMessage, COUPON: "" });
  };

  const handleOnChange = (value: string, reason: string) => {
    if (reason === "clear") {
      setCoupon([]);
    }
    setErrorMessage({ ...initStateErrorMessage, COUPON: "" });
  };

  const handleRenderTags = (value: string[], getTagProps: any) => {
    return _.map(value, (option: string, index: number) => (
      <Chip
        label={option}
        {...getTagProps({ index })}
        onDelete={
          _.includes(option, discountPeriod?.label) ||
          _.includes(option, discountTurn1?.label) ||
          _.includes(option, discountTurn1Down?.label)
            ? undefined
            : () => handleDeleteCoupon(option)
        }
      />
    ));
  };

  const checkDuplicate = () => {
    return _.some(coupon, { coupon_label: couponValue });
  };

  return (
    <>
      <Loading show={checkCouponLoading} type="fullLoading" />
      <AutocompleteSelect
        labelId="coupon"
        heading="คูปอง"
        placeholder={"กรอกคูปอง และกด Enter เพื่อเพิ่ม"}
        helperText={errorMessage.COUPON}
        freeSolo={true}
        multiple={true}
        options={[]}
        value={
          _.isEmpty(discountPeriod) && _.isEmpty(discountTurn1)
            ? []
            : _.compact([
                discountTurn1Down?.label,
                discountPeriod?.label,
                discountTurn1?.label,
                ..._.map(coupon, "coupon_label"),
              ])
        }
        inputValue={couponValue}
        onInputChange={handleInputCouponChange}
        onChange={(event, value, reason) => handleOnChange(value, reason)}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => handleCouponKeyDown(event)}
        renderTags={(value: string[], getTagProps: any) => handleRenderTags(value, getTagProps)}
        inputMode="search"
      />
      <SubText label={"กรอกคูปอง และกด Enter เพื่อเพิ่ม"} />
    </>
  );
}
