import { Box } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PartnerApi from "../../api-partner/partner/partner.api";
import AuthenApi from "../../api/authen/authen.api";
import OptionsApi from "../../api/option/option.api";
import Loading from "../../components/loading/loading";
import { swalError, swalCustom } from "../../components/notification/swal";
import { colors } from "../../constants/colors";
import { defaultDataRegister } from "./default";
import LayoutCareer from "./layout-career/layout-career";
import LayoutComplete from "./layout-complete/layout-complete";
import LayoutContact from "./layout-contact/layout-contact";
import LayoutPersonal from "./layout-personal/layout-personal";
import LayoutProduct from "./layout-product/layout-product";
import LayoutRegister from "./layout-register/layout-register";
import LayoutEstimate from "./layout-estimate/layout-estimate";
import LayoutProductTurn1 from "./layout-product/layout-product-turn1";
import { REGISTER_OPTIONS_TYPE, REGISTER_TYPE } from "../../utils/enum";
import LayoutProductTurn2 from "./layout-product/layout-product-turn2";
import LayoutProductSecondhand from "./layout-product/layout-product-secondhand";
import _ from "lodash";
import LayoutPersonalSecondhand from "./layout-personal/layout-personal-secondhand";
import LarkService from "../../utils/lark/lark";

const stepsInstallment = ["ลงทะเบียน", "สินค้า", "ส่วนตัว", "อาชีพ", "ผู้ติดต่อ"];
const stepsTurn = ["ลงทะเบียน", "ประเมินสินค้า", "สินค้า", "ส่วนตัว", "อาชีพ", "ผู้ติดต่อ"];
const stepsSecondhand = ["ลงทะเบียน", "ประเมินสินค้า", "สินค้า", "ส่วนตัว"];
const INIT_STEP = 0;

const { INSTALLMENT, TURN1, TURN2, SECONDHAND } = REGISTER_TYPE;

interface WindowWithAdTech extends Window {
  adTechTrackerInit?: (key: string) => void;
  tmfire?: (publicKey: string, eventData: object) => void;
}

export default function Register() {
  const { pathname, search } = useLocation();
  const [activeStep, setActiveStep] = useState<number>(INIT_STEP);
  const [data, setData] = useState<any>(defaultDataRegister);
  const [checkedItems, setCheckedItems] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [optionMaster, setOptionMaster] = useState<any[]>([]);
  const [steps, setSteps] = useState<any>([]);
  const [showSteps, setShowSteps] = useState<number>(0);
  const [optionsPeriodChoice, setOptionsPeriodChoice] = useState<any[]>([]);
  const [estimateHeader, setEstimateHeader] = useState<any>({});

  const params = new URLSearchParams(search);
  const PARAMS_TYPE = params.get('type');

  useEffect(() => {
    let steps: string[] = [];
    let showSteps: number = 0;
    switch (data?.type) {
      case INSTALLMENT:
        steps = stepsInstallment;
        showSteps = 2;
        break;
      case TURN1:
      case TURN2:
        steps = stepsTurn;
        showSteps = 3;
        break;
      case SECONDHAND:
        steps = stepsSecondhand;
        showSteps = 3;
        break;
      default:
        break;
    }
    setShowSteps(showSteps);
    setSteps(steps);
  }, [data?.type]);

  // check version first
  const checkVersionAndReload = async () => {
    try {
      const webVersion = process.env.REACT_APP_WEB_VERSION; // เวอร์ชันจาก env
      const response = await AuthenApi.checkWebVersion({ webVersion });
      return { response: response?.data };
    } catch (error) {
      return { response: false };
    }
  };

  const fetchVersionAndHandleUpdate = async () => {
    const responeCheck = await checkVersionAndReload();
    if (!responeCheck?.response) {
      swalCustom(
        "ระบบมีการอัปเดตเวอร์ชั่น",
        "กรุณากดตกลงเพื่อดำเนินการอัปเดตเวอร์ชันใหม่",
        (isConfirmed: boolean) => {
          if (isConfirmed) {
            window.location.reload();
          }
        },
        "warning",
        {
          showCloseButton: false,
          confirmButton: {
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
          },
          cancelButton: {
            showCancelButton: false,
          },
        }
      );
    }
  };

  useEffect(() => {
    fetchVersionAndHandleUpdate();
  }, []);

  const onNextStep = (index: number) => {
    setActiveStep(index);
  };

  const onBackStep = (index: number) => {
    setActiveStep(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    const type = !_.includes(_.values(REGISTER_OPTIONS_TYPE), PARAMS_TYPE)
      ? ""
      : PARAMS_TYPE
        ? PARAMS_TYPE
        : defaultDataRegister.type;
    setData({ ...defaultDataRegister, type: type });
  }, [defaultDataRegister, PARAMS_TYPE]);

  const loadAddress = useCallback(() => {
    OptionsApi.address().then((res) => {
      if (res.status === 200) {
        setOptionMaster(res.data.address);
      }
    });
  }, []);

  useEffect(() => {
    loadAddress();
  }, [loadAddress]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_API_ADTECH || "";
    script.async = true;
    const customWindow = window as WindowWithAdTech;
    script.onload = () => {
      try {
        if (customWindow.adTechTrackerInit) {
          customWindow.adTechTrackerInit(process.env.REACT_APP_API_ADTECH_KEY || '');
        } else {
          throw new Error("adTechTrackerInit function is not available");
        }
      } catch (error: any) {
        console.error("Error in adTechTrackerInit:", error);
        new LarkService('adTechTrackerInit error', 'red').send(
          `error: ${error?.message}  ${JSON.stringify(customWindow, null, 2)}`
        );
      }
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onSubmit = async () => {
    if (data) {
      setLoading(true);
      if (data.job?.studentImages?.length > 0) {
        data.job.studentImages.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.job?.transcriptOrTermFees?.length > 0) {
        data.job.transcriptOrTermFees.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.profile?.imeiImages?.images?.length > 0) {
        data.profile?.imeiImages?.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.profile?.iphoneStorage?.images?.length > 0) {
        data.profile?.iphoneStorage?.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.statement?.images?.length > 0) {
        data.statement.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.proveWork?.length > 0) {
        data.proveWork.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.riderAppication?.length > 0) {
        data.riderAppication.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.governmentOfficial?.length > 0) {
        data.governmentOfficial.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.proveOwnBusinessImage?.length > 0) {
        data.proveOwnBusinessImage.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.salesChannelsImage?.images?.length > 0) {
        data.salesChannelsImage?.images?.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }

      if (data?.estimateValue) {
        delete data?.estimateValue;
      }

      //เพิ่ม data register_web_version ทุก record ตอน save
      data["register_web_version"] = process.env.REACT_APP_WEB_VERSION;
      const contentName = data?.type == 'INSTALLMENT' ? 'L1' : data?.type == 'TURN1' ? 'T1' : ''
      const customWindow = window as WindowWithAdTech;
      const publicKey = process.env.REACT_APP_API_ADTECH_KEY || "";
      const eventData = {
        data: {
          event_name: "Lead",
          custom_data: {
            currency: "thb",
            value: 10,
            content_name: contentName
          },
        },
      };

      if (customWindow.tmfire) {
        customWindow.tmfire(publicKey, eventData);
        // new LarkService('adTechTracker register send event', 'green').send(
        //   `send : \n${eventData.toString()}`
        // );
      }

      const res = await AuthenApi.registerBeforeVerify(data)
        .then(async (e) => {
          setLoading(false);
          window.scrollTo(0, 0);
          setActiveStep((prev) => prev + 1);
          return e.data;
        })
        .catch((e: any) => {
          setLoading(false);
          if (e.response.status == 500) {
            return swalError("ระบบมีปัญหากรุณาลองใหม่อีกครั้ง");
          }
        });
      if (data?.chatcone?.social_id) {
        const { id } = res;
        const payload = {
          chatcone: data?.chatcone,
          process_type: "REGISTER",
          register_id: id,
          ref_id: id,
          route_from: `${process.env.REACT_APP_URL}${pathname}`,
        };
        await PartnerApi.sendChatconeByProcess(payload);
      }
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", paddingTop: 20 }}>
      <Loading show={loading} type="fullLoading" />
      <div>
        {activeStep >= showSteps && (
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: colors.themeMainColor,
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                color: colors.black,
              },
            }}
          >
            {steps.map((label: string) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        <div>
          {activeStep === 0 && (
            <LayoutRegister
              onClickNext={() => {
                onNextStep(activeStep + 1);
              }}
              data={data}
              onChange={setData}
              optionMaster={optionMaster}
            />
          )}

          {/* สินค้า INSTALLMENT */}
          {activeStep === 1 && data?.type === INSTALLMENT && (
            <LayoutProduct
              onClickNext={() => {
                onNextStep(activeStep + 1);
              }}
              data={data}
              onChange={setData}
              checkedItemsVal={setCheckedItems}
              checkedItems={checkedItems}
              optionsPeriodChoice={optionsPeriodChoice}
              setOptionsPeriodChoice={setOptionsPeriodChoice}
            />
          )}

          {/* ประเมินสินค้า */}
          {activeStep === 1 && _.includes([TURN1, TURN2, SECONDHAND], data?.type) && (
            <LayoutEstimate
              onClickNext={() => {
                onNextStep(activeStep + 1);
              }}
              data={data}
              onChange={setData}
              estimateHeader={estimateHeader}
              setEstimateHeader={setEstimateHeader}
            />
          )}

          {/* สินค้า TURN1 */}
          {activeStep === 2 && data?.type === TURN1 && (
            <LayoutProductTurn1
              data={data}
              onClickNext={() => {
                onNextStep(activeStep + 1);
              }}
              onClickBack={() => {
                onBackStep(activeStep - 1);
              }}
              onChange={setData}
              optionsPeriodChoice={optionsPeriodChoice}
              setOptionsPeriodChoice={setOptionsPeriodChoice}
            />
          )}

          {/* สินค้า TURN2 */}
          {activeStep === 2 && data?.type === TURN2 && (
            <LayoutProductTurn2
              onClickNext={() => {
                onNextStep(activeStep + 1);
              }}
              onClickBack={() => {
                onBackStep(activeStep - 1);
              }}
              data={data}
              onChange={setData}
              checkedItemsVal={setCheckedItems}
              checkedItems={checkedItems}
              optionsPeriodChoice={optionsPeriodChoice}
              setOptionsPeriodChoice={setOptionsPeriodChoice}
            />
          )}

          {/* สินค้า SECONDHAND */}
          {activeStep === 2 && data?.type === SECONDHAND && (
            <LayoutProductSecondhand
              onClickNext={() => {
                onNextStep(activeStep + 1);
              }}
              onClickBack={() => {
                onBackStep(activeStep - 1);
              }}
              data={data}
              onChange={setData}
            />
          )}

          {/* ส่วนตัว */}
          {((activeStep === 2 && data?.type === INSTALLMENT) ||
            (activeStep === 3 && _.includes([TURN1, TURN2], data?.type))) && (
              <LayoutPersonal
                onClickNext={() => {
                  onNextStep(activeStep + 1);
                }}
                onClickBack={() => {
                  onBackStep(activeStep - 1);
                }}
                data={data}
                onChange={setData}
                optionMaster={optionMaster}
              />
            )}

          {/* {((activeStep === 2 && data?.type === INSTALLMENT) ||
            (activeStep === 3 && _.includes([TURN1, TURN2], data?.type))) && (
              <LayoutPersonalSecondhand
                onClickNext={() => {
                  onNextStep(activeStep + 1);
                }}
                onClickBack={() => {
                  onBackStep(activeStep - 1);
                }}
                data={data}
                onChange={setData}
                optionMaster={optionMaster}
                onSubmit={() => {
                  onSubmit();
                }}
              />
            )} */}

          {/* ส่วนตัว SECONDHAND */}
          {activeStep === 3 && data?.type === SECONDHAND && (
            <LayoutPersonalSecondhand
              onClickNext={() => {
                onNextStep(activeStep + 1);
              }}
              onClickBack={() => {
                onBackStep(activeStep - 1);
              }}
              data={data}
              onChange={setData}
              optionMaster={optionMaster}
              onSubmit={() => {
                onSubmit();
              }}
            />
          )}

          {/* อาชีพ */}
          {((activeStep === 3 && data?.type === INSTALLMENT) ||
            (activeStep === 4 && _.includes([TURN1, TURN2], data?.type))) && (
              <LayoutCareer
                onClickNext={() => {
                  onNextStep(activeStep + 1);
                }}
                onClickBack={() => {
                  onBackStep(activeStep - 1);
                }}
                data={data}
                onChange={setData}
              />
            )}

          {/* ผู้ติดต่อ */}
          {((activeStep === 4 && data?.type === INSTALLMENT) ||
            (activeStep === 5 && _.includes([TURN1, TURN2], data?.type))) && (
              <LayoutContact
                onSubmit={() => {
                  onSubmit();
                }}
                onClickBack={() => {
                  onBackStep(activeStep - 1);
                }}
                data={data}
                onChange={setData}
              />
            )}

          {/* complete */}
          {((activeStep === 4 && data?.type === SECONDHAND) ||
            (activeStep === 5 && data?.type === INSTALLMENT) ||
            (activeStep === 6 && _.includes([TURN1, TURN2], data?.type))) && (
              <LayoutComplete profile={data.profile} type={data.type} chatcone={data.chatcone} />
            )}
        </div>
      </div>
    </Box>
  );
}
