import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import ButtonCustom from "../../../components/button/buttonCustom";
import InputTextField from "../../../components/input/inputTextField";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import { checkPattenPhoneNumber, checkThaiLang, chkDigitPid } from "../../../utils/common";
import { getAgeMonth } from "../../../utils/date.utils";
import { optionsPrefix } from "../../../utils/options";
import { fromRegister } from "../type-register";
import * as Styles from "./layout-personal.style";
import { useLocation } from "react-router-dom";
import { swalCustom } from "../../../components/notification/swal";
import PdpaApi from "../../../api-pdpa/pdpa/pdpa.api";
import ModalCustom from '../../../components/modalCustom/modalCustom';
import { colors } from '../../../constants/colors';
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { amplitudeTrack } from '../../../utils/amplitude/amplitude';
import { imgs } from "../../../constants/images";

const initStateErrorMessage = {
  PERSONAL_PREFIX: ``,
  PERSONAL_OTHERPREFIX: ``,
  PERSONAL_NAME: ``,
  PERSONAL_LASTNAME: ``,
  PERSONAL_IDCARD: ``,
  PERSONAL_DATEOFBIRTH: ``,
  PERSONAL_PHONENUMBER: ``,
  PERSONAL_ADDRESS: ``,
  PERSONAL_ZIPCODE: ``,
  PERSONAL_PROVINCE: ``,
  PERSONAL_DISTRICT: ``,
  PERSONAL_SUBDISTRICT: ``,
};

interface props {
  onClickNext: () => void;
  onClickBack: () => void;
  data: any;
  onChange?: (value: any) => void;
  optionMaster: any;
  onSubmit: () => void;
}
export default function LayoutPersonalSecondhand(props: props) {
  const [data, setData] = useState<fromRegister>(props.data);
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [prefix, setPrefix] = useState<string>(
    props.data?.profile?.prefix === "นาย" ||
      props.data?.profile?.prefix === "นาง" ||
      props.data?.profile?.prefix === "นางสาว" ||
      props.data?.profile?.prefix === "" ||
      props.data?.profile?.prefix === undefined
      ? props.data?.profile?.prefix
      : "อื่นๆ"
  );
  const [prefixOther, setPrefixOther] = useState<string>(props.data?.profile?.prefix || "");
  const [firstname, setFirstName] = useState<string>(props.data?.profile?.firstName || "");
  const [lastname, setLastname] = useState<string>(props.data?.profile?.lastName || "");
  const [idCard, setIdCard] = useState<string>(props.data?.profile?.idCard || "");
  const [birthday, setBirthday] = useState<string>(props.data?.profile?.dateOfBirth || "");
  const [birthdayFlag, setBirthdayFlag] = useState<boolean>(true);
  const [convertBirthday, setConvertBirthday] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [ageAlert, setAgeAlert] = useState<boolean>(false);
  const [ageAlertInvalid, setAgeAlertInvalid] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(props.data?.profile?.phoneNumber || "");
  const [address, setAddress] = useState<string>(props.data?.profile?.address || "");
  const [zipCode, setZipCode] = useState<string>(props.data?.profile?.zipcode || "");
  const [province, setProvince] = useState<string>(props.data?.profile?.province || "");
  const [district, setDistrict] = useState<string>(props.data?.profile?.district || "");
  const [subdistrict, setSubdistrict] = useState<string>(props.data?.profile?.subdistrict || "");
  const [optionProvice, setOptionProvice] = useState<any[]>([]);
  const [optionProviceMaster, setOptionProviceMaster] = useState<any[]>([]);
  const [optionDistrict, setOptionDistrict] = useState<any[]>([]);
  const [optionDistrictMaster, setOptionDistrictMaster] = useState<any[]>([]);
  const [optionSubDistrict, setOptionSubdistrict] = useState<any[]>([]);
  const [checkPhoneNumber, setCheckPhonenumber] = useState<boolean>(false);
  const [checkIdCard, setCheckIdCard] = useState<boolean>(false);
  const [checkDigiPhoneNumber, setCheckDigiPhonenumber] = useState<boolean>(false);
  const [checkZipCode, setCheckZipcode] = useState<boolean>(false);
  const [isSubmit, setiSSubmit] = useState<boolean>(false);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const PARAMS_IDCARD = params.get("iDCard");

  const [pdpaData, setPdpaData] = useState<string>('')
  const [condition, setCondition] = useState<boolean>(false)
  const [isShowModalPdpa, setIsShowMoalPdpa] = useState<boolean>(false)
  const [checkIsShowModalPdpa, setCheckIsShowModalPdpa] = useState<boolean>(false)

  const [conditionModal, setConditionModal] = useState<boolean>(false);
  const [isShowModal, setIsShowMoal] = useState<boolean>(false)

  useEffect(() => {
    if (isShowModalPdpa) {
      PdpaApi.getPdpa().then((res) => {
        if (res?.data?.success) {
          const htmlContent = Array.isArray(res?.data?.html) ? res?.data?.html.join('') : String(res?.data?.html);
          setPdpaData(htmlContent)
        }
      })
    }
  }, [isShowModalPdpa])

  useEffect(() => {
    if (PARAMS_IDCARD && !props.data?.profile?.idCard) {
      setIdCard(PARAMS_IDCARD || "");
    }
  }, [PARAMS_IDCARD]);

  useEffect(() => {
    if (isSubmit) {
      setTimeout(() => {
        return props.onSubmit && props.onSubmit();
      }, 500);

      setiSSubmit(false);
    }
  }, [isSubmit]);

  useEffect(() => { }, [props.data]);

  useEffect(() => {
    if (zipCode?.length === 5 && props.optionMaster.length > 0) {
      const filterProvinceMaster = props.optionMaster
        .filter((val: any) => val.zipcode === zipCode)
        .map((data: any) => {
          return data;
        });
      const filterProvince = props.optionMaster
        .filter((val: any) => val.zipcode === zipCode)
        .map((data: any) => {
          return { value: data.province, label: data.province };
        });
      if (filterProvinceMaster.length === 0) {
        setCheckZipcode(true);
      }
      setOptionProviceMaster(filterProvinceMaster);
      setOptionProvice(duplicate(filterProvince));

      const provinceMaster = duplicate(filterProvince);
      setOptionProviceMaster(filterProvinceMaster);
      setOptionProvice(provinceMaster);

      /* set province auto */
      if (!_.isEmpty(provinceMaster)) {
        setProvince(provinceMaster[0]?.value);
      }
    } else {
      setOptionProviceMaster([]);
      setOptionProvice([]);
      setOptionDistrictMaster([]);
      setOptionDistrict([]);
      setOptionSubdistrict([]);
      setProvince("");
      setDistrict("");
      setSubdistrict("");
    }
  }, [zipCode]);

  useEffect(() => {
    if (province) {
      const filterDistrictMaster = optionProviceMaster
        .filter((val: any) => val.province === province)
        .map((data: any) => {
          return data;
        });
      const filterDistrict = optionProviceMaster
        .filter((val: any) => val.province === province)
        .map((data: any) => {
          return { value: data.amphoe, label: data.amphoe };
        });
      setOptionDistrictMaster(filterDistrictMaster);
      setOptionDistrict(duplicate(filterDistrict));
    } else {
      setOptionSubdistrict([]);
      setDistrict("");
      setSubdistrict("");
    }
  }, [province, optionProviceMaster]);

  useEffect(() => {
    if (district) {
      const filterSubdistrict = optionDistrictMaster
        .filter((val: any) => val.amphoe === district)
        .map((data: any) => {
          return { value: data.district, label: data.district };
        });
      setOptionSubdistrict(duplicate(filterSubdistrict));
    } else {
      setOptionSubdistrict([]);
      setSubdistrict("");
    }
  }, [district, optionDistrictMaster]);

  useEffect(() => {
    if (birthday && birthdayFlag && props.data?.profile?.dateOfBirth) {
      const dob = moment(birthday, "MM/DD/YYYY").add(543, "years").format("DD/MM/YYYY");
      setBirthday(dob);
      setBirthdayFlag(false);
      setConvertBirthday(props.data?.profile?.dateOfBirth);
    } else {
      if (birthday.length === 10) {
        const age = getAgeMonth(birthday);
        if (age.years > 0 && age.months >= 0) {
          setAge(`${age.years} ปี ${age.months} เดือน`);
          if (age.years > 70 || age.years < 15) {
            setErrorMessage({ ...errorMessage, PERSONAL_DATEOFBIRTH: "ผู้ทำสัญญาต้องมีอายุระหว่าง 20-60 ปี" });
            setAgeAlert(true);
          } else {
            setErrorMessage({ ...errorMessage, PERSONAL_DATEOFBIRTH: "" });
            setAgeAlert(false);
          }

          setAgeAlertInvalid(false);
        } else {
          setAge("0 ปี 0 เดือน");
          setAgeAlert(false);
          setAgeAlertInvalid(true);
          if (birthday) {
            setErrorMessage({ ...errorMessage, PERSONAL_DATEOFBIRTH: "วัน/เดือน/ปีเกิด ไม่ถูกต้อง" });
          }
        }
      } else {
        if (birthday.length !== 10) setAgeAlertInvalid(true);
      }
    }
  }, [birthday]);

  useEffect(() => {
    if (idCard && idCard.length === 13) {
      const check = chkDigitPid(idCard);
      if (!check) {
        setCheckIdCard(true);
        const msg: any = {
          PERSONAL_IDCARD: !check ? "กรอกรหัสบัตรประชาชนไม่ถูกต้อง" : "",
        };
        setErrorMessage({ ...errorMessage, ...msg });
      } else {
        setCheckIdCard(false);
      }
    }
  }, [idCard]);

  const handleChangeDate = (event: any) => {
    if (event.target.value.length <= 10) {
      let inputDate = event.target.value;
      inputDate = inputDate.replace(/\D/g, "");
      if (inputDate.length <= 2) {
        inputDate = inputDate.replace(/^(\d{0,2})/, "$1");
      } else if (inputDate.length <= 4) {
        inputDate = inputDate.replace(/^(\d{0,2})(\d{0,2})/, "$1/$2");
      } else {
        inputDate = inputDate.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, "$1/$2/$3").slice(0, 10);
      }
      setBirthday(inputDate);
      const convertDob = moment(inputDate, "DD/MM/YYYY").subtract(543, "years").format("MM/DD/YYYY");
      setConvertBirthday(convertDob);
      setAge("0 ปี 0 เดือน");
      setErrorMessage({ ...errorMessage, PERSONAL_DATEOFBIRTH: "" });
    }
  };

  const duplicate = (data: any) => {
    if (data.length > 0) {
      return data.filter((el: any, id: number) => id === data.findIndex((ele: any) => ele.value === el.value));
    }
  };

  useEffect(() => {
    if (phoneNumber && phoneNumber.length === 10) {
      const check = checkPattenPhoneNumber(phoneNumber);
      setCheckPhonenumber(!check);
      setCheckDigiPhonenumber(false);
    } else {
      setCheckDigiPhonenumber(true);
    }
  }, [phoneNumber]);

  const onCheckData = async () => {
    if (
      !prefix ||
      !firstname ||
      !lastname ||
      !idCard ||
      idCard.length < 13 ||
      !birthday ||
      !phoneNumber ||
      !address ||
      !province ||
      !district ||
      !subdistrict ||
      !zipCode ||
      checkPhoneNumber ||
      checkDigiPhoneNumber ||
      zipCode?.length !== 5 ||
      (prefix === "อื่นๆ" && !prefixOther) ||
      checkIdCard ||
      ageAlert ||
      ageAlertInvalid
    ) {
      const msg: any = {
        PERSONAL_PREFIX: !prefix ? "โปรดเลือกคำนำหน้าชื่อ" : "",
        PERSONAL_NAME: !firstname ? "โปรดกรอกชื่อ" : "",
        PERSONAL_LASTNAME: !lastname ? "โปรดกรอกนามสกุล" : "",
        PERSONAL_IDCARD: !idCard
          ? "โปรดกรอกรหัสบัตรประชาชน"
          : idCard.length < 13
            ? "โปรดกรอกรหัสบัตรประชาชนให้ครบ 13 หลัก"
            : checkIdCard
              ? "กรอกรหัสบัตรประชาชนไม่ถูกต้อง"
              : "",
        PERSONAL_DATEOFBIRTH: !birthday
          ? "โปรดกรอกวัน/เดือน/ปีเกิด"
          : ageAlertInvalid
            ? "วัน/เดือน/ปีเกิด ไม่ถูกต้อง"
            : ageAlert
              ? "ผู้ทำสัญญาต้องมีอายุระหว่าง 20-60 ปี"
              : ageAlertInvalid
                ? "วัน/เดือน/ปีเกิด ไม่ถูกต้อง"
                : "",
        PERSONAL_PHONENUMBER: !phoneNumber
          ? "โปรดกรอกเบอร์โทรศัพท์"
          : checkPhoneNumber
            ? "โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง"
            : checkDigiPhoneNumber
              ? "ระบุจำนวน 10 ตัวอักษร"
              : "",
        PERSONAL_ADDRESS: !address ? "โปรดกรอกที่อยู่" : "",
        PERSONAL_PROVINCE: !province ? "โปรดเลือกจังหวัด" : "",
        PERSONAL_DISTRICT: !district ? "โปรดเลือกเขต/อำเภอ" : "",
        PERSONAL_SUBDISTRICT: !subdistrict ? "โปรดเลือกแขวง/ตำบล" : "",
        PERSONAL_ZIPCODE: !zipCode ? 'โปรดกรอกรหัสไปรษณีย์' : zipCode?.length === 0 ? 'รหัสไปรษณีย์ไม่ถูกต้อง' : zipCode.length !== 5 ? 'รหัสไปรษณีย์ไม่ถูกต้อง' : '',
        PERSONAL_OTHERPREFIX: !prefixOther ? "โปรดกรอกคำนำหน้าชื่อ" : "",
      };
      setErrorMessage({ ...initStateErrorMessage, ...msg });

      return false;
    }
    chageData();
  };

  const chageData = () => {
    const setDt = {
      ...data,
      profile: {
        ...data.profile,
        type: data?.type,
        idCard: idCard, //เลขบัตรปชช
        phoneNumber: phoneNumber, // เบอร์โทรศัพท์
        prefix: prefix === "อื่นๆ" ? prefixOther : prefix, //  คำนำหน้าชื่อ
        firstName: firstname, // ชื่อ
        lastName: lastname, // นามสกุล
        address: address, //
        zipcode: zipCode, // รหัสไปรษณีย์ที่อยู่ปัจจุบัน
        subdistrict: subdistrict, // แขวง
        district: district, // เขต
        province: province, // จังหวัด
        dateOfBirth: convertBirthday,
      },
    };

    if (props.onChange) props.onChange(setDt);

    setIsShowMoal(true)
  };

  const onClickBack = async () => {
    await setData({
      ...data,
      profile: {
        ...data.profile,
        idCard: idCard, //เลขบัตรปชช
        phoneNumber: phoneNumber, // เบอร์โทรศัพท์
        prefix: prefix === "อื่นๆ" ? prefixOther : prefix, //  คำนำหน้าชื่อ
        firstName: firstname, // ชื่อ
        lastName: lastname, // นามสกุล
        address: address, //
        zipcode: zipCode, // รหัสไปรษณีย์ที่อยู่ปัจจุบัน
        subdistrict: subdistrict, // แขวง
        district: district, // เขต
        province: province, // จังหวัด
        dateOfBirth: convertBirthday, // วันเกิด
      },
    });
    setTimeout(() => {
      return props.onClickBack && props.onClickBack();
    }, 500);
  };

  return (
    <Styles.Container>
      <Styles.MarginRow>
        <Col xs={12} md={prefix === "อื่นๆ" ? 3 : 4} className="pt-2">
          <AutocompleteSelect
            options={optionsPrefix || []}
            value={optionsPrefix ? optionsPrefix?.find((val: any) => val?.value === prefix) : null}
            placeholder={"เลือกคำนำหน้าชื่อ"}
            getOptionLabel={(option: any) => option.label}
            labelId="contracttype"
            onChange={(event, value, reason) => {
              if (value) {
                setErrorMessage({ ...errorMessage, PERSONAL_PREFIX: "" });
                setErrorMessage({ ...errorMessage, PERSONAL_OTHERPREFIX: "" });
                if (value.value !== "อื่นๆ") {
                  setPrefixOther("");
                }
                setPrefix(value.value);
              } else if (reason === "clear") setPrefix("");
            }}
            required
            helperText={errorMessage.PERSONAL_PREFIX}
            heading="คำนำหน้าชื่อ"
            key={"prefix-1"}
          />
        </Col>
        {prefix === "อื่นๆ" && (
          <Col xs={12} md={prefix === "อื่นๆ" ? 3 : 4} className="pt-2">
            <InputTextField
              value={prefixOther || ""}
              onchange={(event: any) => {
                setErrorMessage({ ...errorMessage, PERSONAL_OTHERPREFIX: "" });
                setPrefixOther(event.target.value);
              }}
              helperText={errorMessage.PERSONAL_OTHERPREFIX}
              heading="โปรดระบุ"
              placeholder="กรอกคคำนำหน้าชื่อ"
              required
            />
          </Col>
        )}
        <Col xs={12} md={prefix === "อื่นๆ" ? 3 : 4} className="pt-2">
          <InputTextField
            placeholder={"กรอกชื่อ"}
            value={firstname || ""}
            onchange={(event: any) => {
              setErrorMessage({ ...errorMessage, PERSONAL_NAME: "" });
              if (checkThaiLang(event.target.value)) {
                setFirstName(event.target.value);
              } else if (event.target.value === "") {
                setFirstName(event.target.value);
              }
            }}
            helperText={errorMessage.PERSONAL_NAME}
            heading="ชื่อ (ภาษาไทย)"
            required
          />
        </Col>
        <Col xs={12} md={prefix === "อื่นๆ" ? 3 : 4} className="pt-2">
          <InputTextField
            placeholder={"กรอกนามสกุล"}
            value={lastname || ""}
            onchange={(event: any) => {
              setErrorMessage({ ...errorMessage, PERSONAL_LASTNAME: "" });
              if (checkThaiLang(event.target.value)) {
                setLastname(event.target.value);
              } else if (event.target.value === "") {
                setLastname(event.target.value);
              }
            }}
            helperText={errorMessage.PERSONAL_LASTNAME}
            heading="นามสกุล (ภาษาไทย)"
            required
          />
        </Col>
      </Styles.MarginRow>
      <Styles.MarginRow>
        <Col xs={12} md={4} className="pt-2">
          <InputTextField
            placeholder={"กรอกเลขบัตรประชาชน"}
            value={idCard || ""}
            onchange={(event: any) => {
              if (event.target.value.length <= 13) {
                setIdCard(event.target.value);
                setErrorMessage({ ...errorMessage, PERSONAL_IDCARD: "" });
              }
            }}
            required
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            heading="เลขบัตรประชาชน"
            helperText={errorMessage.PERSONAL_IDCARD}
          />
        </Col>
        <Col xs={12} md={4} className="pt-2">
          <InputTextField
            placeholder={"ตัวอย่าง 05/07/2537"}
            value={birthday || ""}
            onchange={(event: any) => {
              handleChangeDate(event);
            }}
            required
            heading="วัน/เดือน/ปีเกิด (พ.ศ.)"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            helperText={errorMessage.PERSONAL_DATEOFBIRTH}
          />
        </Col>
        <Col xs={12} md={4} className="pt-2">
          <InputTextField value={age} disabled heading="อายุ" />
        </Col>
      </Styles.MarginRow>
      <Styles.MarginRow>
        <Col xs={12} md={4} className="pt-2">
          <InputTextField
            placeholder="กรอกเบอร์โทรศัพท์"
            value={phoneNumber || ""}
            onchange={(event: any) => {
              if (event.target.value.length <= 10) {
                setPhoneNumber(event.target.value);
                setErrorMessage({ ...errorMessage, PERSONAL_PHONENUMBER: "" });
              }
            }}
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            required
            heading="เบอร์โทรศัพท์"
            helperText={errorMessage.PERSONAL_PHONENUMBER}
          />
        </Col>
        <Col xs={12} md={8} className="pt-2">
          <InputTextField
            placeholder="กรอกที่อยู่อาศัยปัจจุบัน"
            value={address || ""}
            onchange={(event: any) => {
              setAddress(event.target.value);
              setErrorMessage({ ...errorMessage, PERSONAL_ADDRESS: "" });
            }}
            required
            heading="ที่อยู่อาศัยปัจจุบัน"
            helperText={errorMessage.PERSONAL_ADDRESS}
          />
        </Col>
      </Styles.MarginRow>
      <Styles.MarginRow>
        <Col xs={12} md={3} className="pt-2">
          <InputTextField
            placeholder="กรอกรหัสไปรษณีย์"
            value={zipCode || ""}
            onchange={(event: any) => {
              if (event.target.value.length <= 5) {
                setZipCode(event.target.value);
                setCheckZipcode(false);
                setErrorMessage({ ...errorMessage, PERSONAL_ZIPCODE: "" });
              }
            }}
            required
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            heading="รหัสไปรษณีย์"
            helperText={errorMessage.PERSONAL_ZIPCODE}
          />
        </Col>
        <Col xs={12} md={3} className="pt-2">
          <AutocompleteSelect
            key={"province"}
            options={optionProvice || []}
            value={_.find(optionProvice, (val: any) => val.value === province) || null}
            defaultValue={_.find(optionProvice, (val: any) => val.value === province) || null}
            placeholder={"เลือกจังหวัด"}
            getOptionLabel={(option: any) => option.label}
            labelId="province"
            onChange={(event, value, reason) => {
              if (value) {
                setErrorMessage({ ...errorMessage, PERSONAL_PROVINCE: "" });
                setProvince(value.value);
              } else if (reason === "clear") setProvince("");
            }}
            required
            heading="จังหวัด"
            helperText={errorMessage.PERSONAL_PROVINCE}
          />
        </Col>
        <Col xs={12} md={3} className="pt-2">
          <AutocompleteSelect
            key={"district"}
            options={optionDistrict || []}
            value={_.find(optionDistrict, (val: any) => val.value === district) || null}
            placeholder={"เลือกเขต/อำเภอ"}
            getOptionLabel={(option: any) => option.label}
            labelId="district"
            onChange={(event, value, reason) => {
              if (value) {
                setErrorMessage({ ...errorMessage, PERSONAL_DISTRICT: "" });
                setDistrict(value.value);
              } else if (reason === "clear") setDistrict("");
            }}
            required
            heading="เขต/อำเภอ"
            helperText={errorMessage.PERSONAL_DISTRICT}
          />
        </Col>
        <Col xs={12} md={3} className="pt-2">
          <AutocompleteSelect
            key={"subdistrict"}
            options={optionSubDistrict || []}
            value={_.find(optionSubDistrict, (val: any) => val.value === subdistrict) || null}
            placeholder={"เลือกแขวง/ตำบล"}
            getOptionLabel={(option: any) => option.label}
            labelId="subdistrict"
            onChange={(event, value, reason) => {
              if (value) {
                setErrorMessage({ ...errorMessage, PERSONAL_SUBDISTRICT: "" });
                setSubdistrict(value.value);
              } else if (reason === "clear") setSubdistrict("");
            }}
            required
            heading="แขวง/ตำบล"
            helperText={errorMessage.PERSONAL_SUBDISTRICT}
          />
        </Col>
      </Styles.MarginRow>

      <Styles.MarginRow className='pt-2'>
        <div className="d-flex">
          <FormControlLabel
            control={<Checkbox checked={condition || false}
              onChange={(e: any) => (setCondition(e.target.checked))}
            />}
            label="ยอมรับเงื่อนไขข้อตกลง" />
          <div style={{ color: colors.themeMainColor, cursor: 'pointer', marginBlock: 'auto', textDecoration: 'underline' }}
            onClick={() => {
              setIsShowMoalPdpa(true)
              if (!checkIsShowModalPdpa) {
                amplitudeTrack('4_Contact_P_policy')
              }
            }}>
            นโยบายความเป็นส่วนตัว
          </div>
        </div>
      </Styles.MarginRow>

      <Styles.MarginRow className="pt-2">
        <Col>
          <ButtonCustom
            variant={"outline"}
            btnStyle={{ width: "100%" }}
            textButton={"ย้อนกลับ"}
            onClick={() => {
              onClickBack();
            }}
          />
        </Col>
        <Col>
          <ButtonCustom
            disabled={!condition}
            btnStyle={{ width: "100%" }}
            textButton={"ลงทะเบียน"}
            onClick={() => {
              onCheckData();
            }}
          />
        </Col>
      </Styles.MarginRow>
      <ModalCustom
        size="lg"
        title={'คำเตือน'}
        modalTheme="default"
        onCancel={() => {
          amplitudeTrack('5_Warn_B_cancel')
          setIsShowMoal(false)
        }}
        onSubmit={() => { setiSSubmit(true) }}
        onClose={() => {
          amplitudeTrack('5_Warn_B_x')
          setIsShowMoal(false)
        }}
        textCancel={'ยกเลิก'}
        textBtnConfirm={'ยืนยัน'}
        isShowProps={isShowModal}
        closeButton
        disabledSubmit={!conditionModal}
        footerInline
        component={
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <img height="auto" width="40%" src={imgs.warningAlert} />
            </div>
            <FormGroup className="d-flex flex-column">
              <FormControlLabel
                control={
                  <Checkbox checked={conditionModal || false}
                    onChange={(e: any) => {
                      setConditionModal(e.target.checked)
                      // if (!conditionModal) amplitudeTrack('5_Warn_P_agreement')
                    }}
                  />}
                label="ข้าพเจ้ารับทราบว่าการรับจ้างทำสัญญา หรือยินยอมทำสัญญาแทนผู้อื่น มีโทษทางกฎหมาย ทั้งทางคดีอาญาและทางแพ่ง" />
            </FormGroup>
          </>
        }
      />

      <ModalCustom
        size="xl"
        title={''}
        modalTheme="default"
        onSubmit={() => { setIsShowMoalPdpa(false) }}
        onClose={() => { setIsShowMoalPdpa(false) }}
        textBtnConfirm={'ยืนยัน'}
        isShowProps={!!pdpaData && isShowModalPdpa}
        closeButton
        component={<div style={{ maxHeight: '40vh', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: pdpaData }} />}
      />
    </Styles.Container>
  );
}
