import { Avatar, Box, Button, Card, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import iconFb from "../../assets/icon/iconFB.svg";
import iconLine from "../../assets/icon/iconLine.svg";
import iconYt from "../../assets/icon/iconYT.svg";
import iconTiktok from "../../assets/icon/iconTiktok.svg";
import moon from "../../assets/images/moon.svg";
import ShoplinkSVG from "../../assets/images/shoplink";
import { colors } from "../../constants/colors";
import { imgs } from "../../constants/images";
import './animation.css';
import './train.css';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { useLocation } from "react-router-dom";
import { amplitudeTrack } from "../../utils/amplitude/amplitude";
import { getDeviceId } from "@amplitude/analytics-browser";

export default function ShopLink() {
    const [theme, setTheme] = useState<boolean>(false)
    const deviceId = getDeviceId()
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const PARAMS_PLATFROM = params.get('platfrom');
    const PARAMS_CHANNEL = params.get('channel');
    // const [cookies, setCookie] = useCookies(['ampId']);
    // useEffect(() => {
    //     setTimeout(() => {
    //         console.log(!cookies?.ampId)
    //         console.log(deviceId)

    //         if (!cookies?.ampId) {
    //             console.log(deviceId)
    //             if (deviceId !== undefined && deviceId !== null) {
    //                 console.log(deviceId)
    //                 setCookie('ampId', deviceId, { path: '/' });
    //             }
    //         }
    //     }, 500);

    // }, [deviceId]);
    const urlLinkline = (val: string) => {
        switch (val) {
            case 'ufriendSHOP':
                return 'https://lin.ee/VFi8gtQ'
            case 'ufriendTH':
                return 'https://lin.ee/CDQVJpH'
            case 'ufriendBUDDY':
                return 'https://lin.ee/v2ThC7i'
            case 'ufriendJOY':
                return 'https://lin.ee/luGBcdC'
            case 'ufriend4U':
                return 'https://lin.ee/Rzu3EFl'
            case 'ufriendUJAI':
                return 'https://lin.ee/F2677lE'
            case 'ufriendMYDEAR':
                return 'https://lin.ee/Zvmh0Eo'
            case 'ufriendFRESHY':
                return 'https://lin.ee/ZkmpTvC'
            case 'ufriendGANG':
                return 'https://lin.ee/GczOe2p'
            case 'ufriendTH':
                return 'https://lin.ee/CHxcRrt'
            default:
                return 'https://lin.ee/CHxcRrt'
        }
    }

    const navigateTo = (link: any, label?: string) => {
        if (label == 'ผ่อนเลย' || label == 'แลกเงินด่วน') {
            if (PARAMS_PLATFROM !== '' && PARAMS_CHANNEL !== '' && PARAMS_PLATFROM !== null && PARAMS_CHANNEL !== null) {
                const name = label == 'ผ่อนเลย' ? '0_SALEPAGE_L1' : label == 'แลกเงินด่วน' ? '0_SALEPAGE_T1' : ''
                amplitudeTrack(`${name}_Platfrom`, PARAMS_PLATFROM)
                amplitudeTrack(`${name}_Channel`, PARAMS_CHANNEL)
            }
        }
        window.open(link, "_blank");
    }

    const links = [
        { label: "LINE OA", icon: <Box component='img' src={iconLine} sx={{ width: 24, height: 24 }} />, url: urlLinkline(PARAMS_CHANNEL || '') },
        { label: "ผ่อนเลย", icon: '', url: 'https://ufriend.shop/sp_leasing1' },
        { label: "แลกเงินด่วน", icon: '', url: 'https://ufriend.shop/sp_turn1' },
        { label: "Facebook", icon: <Box component='img' src={iconFb} sx={{ width: 24, height: 24 }} />, url: "https://www.facebook.com/ufriendth" },
        { label: "Tiktok", icon: <Box component='img' src={iconTiktok} sx={{ width: 24, height: 24 }} />, url: "https://www.tiktok.com/@ufriendshop" },
        { label: "Youtube", icon: <Box component='img' src={iconYt} sx={{ width: 24, height: 24 }} />, url: "https://www.youtube.com/@ufriendshop" },
        // { label: "064-616-9699", icon: <Box component='img' src={iconTell} sx={{ width: 24, height: 24 }} />, url: "tel:0646169699" },
    ];

    useEffect(() => {
        if (PARAMS_PLATFROM !== '' && PARAMS_CHANNEL !== '' && PARAMS_PLATFROM !== null && PARAMS_CHANNEL !== null) {
            amplitudeTrack('0_Platfrom', PARAMS_PLATFROM)
            amplitudeTrack('0_Channel', PARAMS_CHANNEL)
        }

    }, [PARAMS_PLATFROM, PARAMS_CHANNEL]);
    useEffect(() => {
        const currentDate = new Date();
        const hours = currentDate.getHours();

        if (hours >= 6 && hours < 18) {
            setTheme(false)
        } else {
            setTheme(true)
        }
    }, []);
    return (
        <Box>
            <Box
                sx={{
                    cursor: 'pointer',
                    position: 'fixed',
                    top: 15,
                    right: 15,
                    zIndex: 10
                }}
                onClick={() => { setTheme(!theme) }}
            >
                {theme &&
                    (
                        <DarkModeOutlinedIcon />
                    ) || (
                        <LightModeOutlinedIcon />
                    )}
            </Box>
            <Box sx={{
                textAlign: "center",
                height: "100vh",
                position: "relative",
                overflow: "hidden",
                background: !theme
                    ? 'linear-gradient(to bottom, rgba(145, 162, 242, 0.76) 0%, hsl(200deg 80% 83%) 15%, hsl(0deg 0% 100%) 30%)' :
                    `linear-gradient(to bottom, rgba(145, 162, 242, 0.76) 0%, rgba(0, 81, 186, 1) 15%, hsl(210deg 15% 6%) 30%)`
            }}>
                <Box
                    pt={5}
                    sx={{
                        position: 'absolute',
                        top: { xs: 10, md: 100 },
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: { xs: 50, md: -17, lg: 20 },
                        left: { xs: 81, md: 33, lg: 72 },
                        // right: 0,
                        // bottom: 0,
                        width: {
                            lg: '10%',
                            md: '30%',
                            xs: '40%'
                        },
                        zIndex: 1
                    }} component="img" src={moon} />
                    <div style={{
                        zIndex: 1, position: 'relative',
                        maxWidth: 'revert',
                        width: '100%',
                        minWidth: '320rem'
                    }}>
                        <ShoplinkSVG time={!theme ? 'light' : 'night'} />
                    </div>
                    {theme && (
                        <div className="star-shooting-section">
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                            <div className="star-shooting"></div>
                        </div>
                    ) || (
                            <>
                                <div className="bird-container bird-container--one">
                                    <div className="bird bird--one"></div>
                                </div>
                                <div className="bird-container bird-container--two">
                                    <div className="bird bird--two"></div>
                                </div>
                                <div className="bird-container bird-container--three">
                                    <div className="bird bird--three"></div>
                                </div>
                                <div className="bird-container bird-container--four">
                                    <div className="bird bird--four"></div>
                                </div>
                            </>
                        )}
                </Box>
                <Box sx={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: 'relative',
                    zIndex: 3
                }}>
                    <Card sx={{ p: { xs: 3, md: 5, lg: 7 }, borderRadius: 3, background: colors.white }} >
                        <Avatar
                            src={imgs.newLogo}
                            sx={{ width: 100, height: 100, mx: "auto", mb: 2 }}
                        />
                        <Typography variant="h5"
                            color={colors.black}
                            fontWeight="bold"
                            sx={{
                                typography: { xl: "h5", md: "h5", xs: "h6" },
                                fontWeight: 500
                            }}>สนใจบริการของยูเฟรนด์ กดเลย!</Typography>
                        <Typography color={colors.black} fontWeight="bold" mb={2} mt={1} sx={{
                            typography: { xl: "body1", md: "body1", xs: "body2" },
                        }}>
                            ผ่อนง่าย เทิร์นคุ้ม ใช้บัตรประชาชนใบเดียว
                        </Typography>
                        <Stack spacing={2}>
                            {links.map((link, index) => (
                                <>
                                    {index == 1 && (
                                        <>
                                            <Typography sx={{
                                                typography: { xl: "h6", md: "h6", xs: "h6" },
                                                fontWeight: 500
                                            }} color={colors.black} fontWeight="bold" mb={1} mt={1}>
                                                หรือแสกน QR Code ตรงนี้ได้เลย
                                            </Typography>
                                            <Box mt={0} sx={{}}>
                                                <Box
                                                    component="img"
                                                    src={imgs.mLineOa}
                                                    sx={{ width: { xs: '50%', lg: '70%' } }}
                                                    mt={0}
                                                />
                                            </Box>

                                        </>
                                    )}
                                    <Button
                                        key={index}
                                        variant="contained"
                                        startIcon={link.icon}
                                        // href={link.url}
                                        // target="_blank"
                                        sx={{
                                            borderRadius: 5,
                                            background: "linear-gradient(45deg,rgb(43, 158, 240), #6B6BFF)",
                                            color: "#fff",
                                            "&:hover": {
                                                background: "linear-gradient(45deg,rgb(44, 61, 245), #4B4BFF)", // เปลี่ยนสีตอน hover
                                            },
                                        }}
                                        fullWidth
                                        style={{ color: colors.white }}
                                        onClick={() => { navigateTo(link.url, link.label) }}
                                    >
                                        {link.label}
                                    </Button>
                                </>

                            ))}
                        </Stack>
                    </Card>
                </Box>

                {/* <Box sx={{ position: 'absolute', zIndex: 2, bottom: 0 }}>
                    <Box
                        component="img"
                        src={imgs.cityBg}
                        sx={{ mr: 1, width: { lg: '100%', md: '100%', xs: '100%' } }}
                    />
                </Box> */}
            </Box>

            <Box style={{ zIndex: 3, whiteSpace: 'nowrap' }} sx={{ display: { xs: 'none !important', md: 'block !important' } }}>
                <div id="metro">
                    <span className="back">
                        <div className="door"></div>
                        <div className="mirror">
                            <span className={`${!theme ? 'm1' : 'm1-night'}`}></span>
                            <span className={`${!theme ? 'm2' : 'm2-night'}`}></span>
                            <span className={`${!theme ? 'm3' : 'm3-night'}`}></span>
                            <span className={`${!theme ? 'm4' : 'm4-night'}`}></span>
                        </div>
                        <div className="door2"></div>
                    </span>
                    <span className="back">
                        <div className="door"></div>
                        <div className="mirror">
                            <span className={`${!theme ? 'm1' : 'm1-night'}`}></span>
                            <span className={`${!theme ? 'm2' : 'm2-night'}`}></span>
                            <span className={`${!theme ? 'm3' : 'm3-night'}`}></span>
                            <span className={`${!theme ? 'm4' : 'm4-night'}`}></span>
                        </div>
                        <div className="door2"></div>
                    </span>
                    <span className="engine">
                        <div className="door"></div>
                        <div className="mirror">
                            <span className={`${!theme ? 'm1' : 'm1-night'}`}></span>
                            <span className={`${!theme ? 'm2' : 'm2-night'}`}></span>
                            <span className={`${!theme ? 'm3' : 'm3-night'}`}></span>
                            <span className={`${!theme ? 'm4' : 'm4-night'}`}></span>
                        </div>
                        <div className="door2"></div>
                        <div className={`${!theme ? 'glass' : 'glass-night'}`}></div>
                    </span>

                </div>
                <div className="track">
                </div>

            </Box>

        </Box >

    );
};
