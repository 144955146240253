import ButtonCustom from "../../../components/button/buttonCustom";
import { Col, FormGroup } from "react-bootstrap";
import { optionRelationship } from "../../../utils/options";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import * as Styles from "./layout-contact.style";
import { useCallback, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import InputTextField from "../../../components/input/inputTextField";
import { colors } from "../../../constants/colors";
import InputTextarea from "../../../components/input/InputTextarea";
import ModalCustom from "../../../components/modalCustom/modalCustom";
import { imgs } from "../../../constants/images";
import _ from "lodash";
import { checkPattenPhoneNumber, checkThaiLang } from "../../../utils/common";
import { swalError } from "../../../components/notification/swal";
import { getAgeMonthEn } from '../../../utils/date.utils';
import { PDPA } from "../pdpa";
import HelperText from "../../../components/input/helperText";
import { amplitudeTrack } from "../../../utils/amplitude/amplitude";
import OptionsApi from "../../../api/option/option.api";
import { REGISTER_TYPE } from "../../../utils/enum";

import PdpaApi from "../../../api-pdpa/pdpa/pdpa.api";
interface props {
  onSubmit: () => void;
  onClickBack: () => void;
  data: any
  onChange?: (value: any) => void
}
const {TURN1, TURN2} = REGISTER_TYPE

const initStateErrorMessage = {
  CONTACT_RELATION1: ``,
  CONTACT_NAME1: ``,
  CONTACT_SURNAME1: ``,
  CONTACT_MOBILE1: ``,
  CONTACT_RELATION2: ``,
  CONTACT_NAME2: ``,
  CONTACT_SURNAME2: ``,
  CONTACT_MOBILE2: ``,
  CONTACT_RELATION3: ``,
  CONTACT_NAME3: ``,
  CONTACT_SURNAME3: ``,
  CONTACT_MOBILE3: ``,
  CONTACT_UKONW: ``,
}

export default function LayoutContact(props: props) {
  const isTurnType = _.includes([TURN1],  props.data?.type)

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [data, setData] = useState<any>(props.data)
  const [contact, setContact] = useState<any>(isTurnType ? [0, 1, 2] : [0])
  const [condition, setCondition] = useState<boolean>(false)
  const [accpetCondition, setAccpetCondition] = useState<boolean>(false)

  const [relationship1, setRelationship1] = useState<string>(props.data?.contact && props.data?.contact[0]?.relationship || '')
  const [firstName1, setFirstName1] = useState<string>(props.data?.contact && props.data?.contact[0]?.firstName || '')
  const [lastName1, setLastName1] = useState<string>(props.data?.contact && props.data?.contact[0]?.lastName || '')
  const [phoneNumber1, setPhoneNumber1] = useState<string>(props.data?.contact && props.data?.contact[0]?.phoneNumber || '')
  const [relationship2, setRelationship2] = useState<string>(props.data?.contact && props.data?.contact[1]?.relationship || '')
  const [firstName2, setFirstName2] = useState<string>(props.data?.contact && props.data?.contact[1]?.firstName || '')
  const [lastName2, setLastName2] = useState<string>(props.data?.contact && props.data?.contact[1]?.lastName || '')
  const [phoneNumber2, setPhoneNumber2] = useState<string>(props.data?.contact && props.data?.contact[1]?.phoneNumber || '')
  const [relationship3, setRelationship3] = useState<string>(props.data?.contact && props.data?.contact[2]?.relationship || '')
  const [firstName3, setFirstName3] = useState<string>(props.data?.contact && props.data?.contact[2]?.firstName || '')
  const [lastName3, setLastName3] = useState<string>(props.data?.contact && props.data?.contact[2]?.lastName || '')
  const [phoneNumber3, setPhoneNumber3] = useState<string>(props.data?.contact && props.data?.contact[2]?.phoneNumber || '')
  const [knownUfriendFrom, setKnownUfriendFrom] = useState<any>(props.data?.knownUfriendFrom || '')
  const [knownUfriendFromData, setKnownUfriendFromData] = useState<any[]>([])
  const [pdpaData, setPdpaData] = useState<string>('')


  const [otherDetial, setOtherDetial] = useState<string>(props.data?.otherDetial || '')
  const [conditionModal, setConditionModal] = useState<boolean>(false)
  const [isShowModal, setIsShowMoal] = useState<boolean>(false)
  const [isShowModalPdpa, setIsShowMoalPdpa] = useState<boolean>(false)
  const [checkPhoneNumber1, setCheckPhonenumber1] = useState<boolean>(false)
  const [checkDigiPhoneNumber1, setCheckDigiPhonenumber1] = useState<boolean>(false)

  const [checkPhoneNumber2, setCheckPhonenumber2] = useState<boolean>(false)
  const [checkDigiPhoneNumber2, setCheckDigiPhonenumber2] = useState<boolean>(false)

  const [checkPhoneNumber3, setCheckPhonenumber3] = useState<boolean>(false)
  const [checkDigiPhoneNumber3, setCheckDigiPhonenumber3] = useState<boolean>(false)

  const [isShowAccpetCheckBox, setIsShowAccpetCheckBox] = useState<boolean>(false)

  const [checkIsShowModalPdpa, setCheckIsShowModalPdpa] = useState<boolean>(false)


  useEffect(() => {
    handleSetAcceptCheckbox()
  }, [data])

  useEffect(() => {
    if (isShowModalPdpa) {
      setCheckIsShowModalPdpa(true)
    }
  }, [isShowModalPdpa]);


  useEffect(() => {
    if (isShowModalPdpa) {
      PdpaApi.getPdpa().then((res) => {
        if (res?.data?.success) {
          const htmlContent = Array.isArray(res?.data?.html) ? res?.data?.html.join('') : String(res?.data?.html);
          setPdpaData(htmlContent)
        }
      })
    }
  }, [isShowModalPdpa])

  const loadKnownUfriendFrom = useCallback(() => {
    OptionsApi.knownUfriend().then((res) => {
      if (res.status === 200) {
        setKnownUfriendFromData(res.data.result)
      }
    })
  }, [])

  useEffect(() => {
    loadKnownUfriendFrom()
  }, [loadKnownUfriendFrom]);

  useEffect(() => {
    if (phoneNumber1 && phoneNumber1.length === 10) {
      const check = checkPattenPhoneNumber(phoneNumber1)
      setCheckPhonenumber1(!check)
      setCheckDigiPhonenumber1(false)
    } else {
      setCheckDigiPhonenumber1(true)
    }
  }, [phoneNumber1]);


  useEffect(() => {
    if (phoneNumber2 && phoneNumber2.length === 10) {
      const check = checkPattenPhoneNumber(phoneNumber2)
      setCheckPhonenumber2(!check)
      setCheckDigiPhonenumber2(false)
    } else {
      setCheckDigiPhonenumber2(true)
    }
  }, [phoneNumber2]);

  useEffect(() => {
    if (phoneNumber3 && phoneNumber3.length === 10) {
      const check = checkPattenPhoneNumber(phoneNumber3)
      setCheckPhonenumber3(!check)
      setCheckDigiPhonenumber3(false)
    } else {
      setCheckDigiPhonenumber3(true)
    }
  }, [phoneNumber3]);

  const onSubmit = async (type: string) => {
    setTimeout(() => {
      if (type === 'BACK') {
        chageData()
        amplitudeTrack('4_Contact_B_back')
        return props.onClickBack && props.onClickBack()
      } else {
        amplitudeTrack('5_Warn_N_confirm')
        return props.onSubmit && props.onSubmit()
      }
    }, 500);
  }

  const onCheckData = async () => {
    if (contact.length > 1) {
      if (!relationship1 || !firstName1 || !lastName1 || !phoneNumber1 ||
        !relationship2 || !firstName2 || !lastName2 || !phoneNumber2 ||
        !relationship3 || !firstName3 || !lastName3 || !phoneNumber3 ||
        checkPhoneNumber1 || checkDigiPhoneNumber1 ||
        checkPhoneNumber2 || checkDigiPhoneNumber2 ||
        checkPhoneNumber3 || checkDigiPhoneNumber3 || !knownUfriendFrom
      ) {
        const msg: any = {
          CONTACT_RELATION1: !relationship1 ? 'โปรดเลือกความสัมพันธ์' : '',
          CONTACT_NAME1: !firstName1 ? 'โปรดกรอกชื่อ' : '',
          CONTACT_SURNAME1: !lastName1 ? 'โปรดกรอกนามสกุล' : '',
          CONTACT_MOBILE1: !phoneNumber1 ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber1 ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : checkDigiPhoneNumber1 ? 'ระบุจำนวน 10 ตัวอักษร' : '',

          CONTACT_RELATION2: !relationship2 ? 'โปรดเลือกความสัมพันธ์' : '',
          CONTACT_NAME2: !firstName2 ? 'โปรดกรอกชื่อ' : '',
          CONTACT_SURNAME2: !lastName2 ? 'โปรดกรอกนามสกุล' : '',
          CONTACT_MOBILE2: !phoneNumber2 ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber2 ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : checkDigiPhoneNumber2 ? 'ระบุจำนวน 10 ตัวอักษร' : '',

          CONTACT_RELATION3: !relationship3 ? 'โปรดเลือกความสัมพันธ์' : '',
          CONTACT_NAME3: !firstName3 ? 'โปรดกรอกชื่อ' : '',
          CONTACT_SURNAME3: !lastName3 ? 'โปรดกรอกนามสกุล' : '',
          CONTACT_MOBILE3: !phoneNumber3 ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber3 ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : checkDigiPhoneNumber3 ? 'ระบุจำนวน 10 ตัวอักษร' : '',

          CONTACT_UKONW: !knownUfriendFrom ? 'กรุณาเลือกท่านรู้จัก Ufriend จาก' : ''
        }
        setErrorMessage({ ...initStateErrorMessage, ...msg })
        return false
      }
      if (firstName1 && firstName2 && firstName3 && phoneNumber1 && phoneNumber2 && phoneNumber3 && knownUfriendFrom && props.data?.profile?.phoneNumber && props.data?.profile?.firstName && props.data?.profile?.lastName) {
        const check = hasDuplicates(firstName1, firstName2, firstName3, '')
        const checkPesonalName = hasDuplicates(firstName1 + lastName1, firstName2 + lastName2, firstName3 + lastName3, props.data?.profile?.firstName + props.data?.profile?.lastName)
        const checkPhone = hasDuplicates(phoneNumber1, phoneNumber2, phoneNumber3, '')
        const checkPersonalPhone = hasDuplicates(phoneNumber1, phoneNumber2, phoneNumber3, props.data?.profile?.phoneNumber)
        if (check) {
          swalError('ชื่อผู้ติดต่อซ้ำ')
          return false
        } else if (checkPesonalName) {
          swalError('ชื่อผู้ลงทะเบียนซ้ำกับผู้ติดต่อ')
          return false
        } else if (checkPhone) {
          swalError('เบอร์ผู้ติดต่อซ้ำ')
          return false
        } else if (checkPersonalPhone) {
          swalError('เบอร์โทรผู้ลงทะเบียนซ้ำกับผู้ติดต่อ')
          return false
        }
        amplitudeTrack('4_Contact_N_register')
        setIsShowMoal(true)
      }
    } else {
      if (!relationship1 || !firstName1 || !lastName1 || !phoneNumber1 || !knownUfriendFrom || checkPhoneNumber1 || checkDigiPhoneNumber1) {
        const msg: any = {
          CONTACT_RELATION1: !relationship1 ? 'โปรดเลือกความสัมพันธ์' : '',
          CONTACT_NAME1: !firstName1 ? 'โปรดกรอกชื่อ' : '',
          CONTACT_SURNAME1: !lastName1 ? 'โปรดกรอกนามสกุล' : '',
          CONTACT_MOBILE1: !phoneNumber1 ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber1 ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : checkDigiPhoneNumber1 ? 'ระบุจำนวน 10 ตัวอักษร' : '',
          CONTACT_UKONW: !knownUfriendFrom ? 'กรุณาเลือกท่านรู้จัก Ufriend จาก' : ''
        }
        setErrorMessage({ ...initStateErrorMessage, ...msg })
        return false
      }
      if (firstName1 && lastName1) {
        const checkPesonalName = hasDuplicates(firstName1 + lastName1, props.data?.profile?.firstName + props.data?.profile?.lastName, '', '')
        const checkPersonalPhone = hasDuplicates(phoneNumber1, props.data?.profile?.phoneNumber, '', '')
        if (checkPesonalName) {
          swalError('ชื่อผู้ลงทะเบียนซ้ำกับผู้ติดต่อ')
          return false
        } else if (checkPersonalPhone) {
          swalError('เบอร์โทรผู้ลงทะเบียนซ้ำกับผู้ติดต่อ')
          return false
        }
      }
      amplitudeTrack('4_Contact_N_register')
      setIsShowMoal(true)
    }
    chageData()
  }
  const chageData = () => {
    let contactData
    if (isTurnType) {
      contactData = [
        {
          "relationship": relationship1,
          "firstName": firstName1,
          "lastName": lastName1,
          "phoneNumber": phoneNumber1,
        },
        {
          "relationship": relationship2,
          "firstName": firstName2,
          "lastName": lastName2,
          "phoneNumber": phoneNumber2,
        },
        {
          "relationship": relationship3,
          "firstName": firstName3,
          "lastName": lastName3,
          "phoneNumber": phoneNumber3,
        }
      ]
      if (props.onChange) props.onChange({ ...data, contact: contactData, knownUfriendFrom: knownUfriendFrom, acceptCondition: accpetCondition, othersDetails: otherDetial })
    } else {
      contactData = [
        {
          "relationship": relationship1,
          "firstName": firstName1,
          "lastName": lastName1,
          "phoneNumber": phoneNumber1,
        }
      ]
    }
    if (props.onChange) props.onChange({ ...data, contact: contactData, knownUfriendFrom: knownUfriendFrom, acceptCondition: accpetCondition, othersDetails: otherDetial })
  }

  function hasDuplicates(param1: string, param2?: string, param3?: string, param4?: string) {

    if (param3 !== '' && param4 !== '') {
      const arr = [param1, param2, param3, param4];
      const set = new Set(arr);
      return set.size !== arr.length;
    } else {
      const arr = [param1, param2];
      const set = new Set(arr);
      return set.size !== arr.length;
    }

  }

  const handleSetAcceptCheckbox = () => {
    if (props?.data?.job?.type === 'บุคคลทั่วไป') {
      const age = getAgeMonthEn(props?.data?.profile?.dateOfBirth)
      if (age?.years < 20 || age?.years > 60) {
        setIsShowAccpetCheckBox(true)
      }
    }
  }

  return (
    <Styles.Container>
      {contact && contact.map((data: any, index: number) => {
        return (
          <Styles.MarginRow>
            <Col xs={12} md={3} className="pt-2">
              <AutocompleteSelect
                options={optionRelationship || []}
                value={_.find(optionRelationship, (val: any) => val.value === (index === 0 ? relationship1 : index === 1 ? relationship2 : relationship3)) || null}
                placeholder={"เลือกความสัมพันธ์"}
                getOptionLabel={(option: any) => option.label}
                labelId="contracttype"
                onChange={(event, value, reason) => {
                  if (value) {
                    if (index === 0) {
                      setErrorMessage({ ...errorMessage, CONTACT_RELATION1: '' })
                      // amplitudeTrack('4_Contact_P_relation1')
                      setRelationship1(value.value)
                    } else if (index === 1) {
                      setErrorMessage({ ...errorMessage, CONTACT_RELATION2: '' })
                      // amplitudeTrack('4_Contact_P_relation2')
                      setRelationship2(value.value)
                    } else {
                      setErrorMessage({ ...errorMessage, CONTACT_RELATION3: '' })
                      // amplitudeTrack('4_Contact_P_relation3')
                      setRelationship3(value.value)
                    }
                  }
                  else if (reason === 'clear') {
                    if (index === 0) {
                      setRelationship1('')
                    } else if (index === 1) {
                      setRelationship2('')
                    } else {
                      setRelationship3('')
                    }
                  }
                }}
                heading="ความสัมพันธ์"
                headingOther="(ผู้ติดต่อต้องอายุมากกว่า 20)"
                required
                helperText={(index === 0 ? errorMessage.CONTACT_RELATION1 : index === 1 ? errorMessage.CONTACT_RELATION2 : errorMessage.CONTACT_RELATION3)}
              />
            </Col>
            <Col xs={12} md={3} className="pt-2">
              <InputTextField
                placeholder={'กรอกเบอร์โทรศัพท์'}
                value={(index === 0 ? phoneNumber1 : index === 1 ? phoneNumber2 : phoneNumber3)}
                // onBlur={() => {
                //   if (index === 0 && phoneNumber1 !== '') {
                //     amplitudeTrack('4_Contact_P_tel1')
                //   } else if (index === 1 && phoneNumber2 !== '') {
                //     amplitudeTrack('4_Contact_P_tel2')
                //   } else {
                //     if (phoneNumber3 !== '') {
                //       amplitudeTrack('4_Contact_P_tel3')
                //     }
                //   }
                // }}
                onchange={(event: any) => {
                  if (index === 0) {
                    if (event.target.value.length <= 10) {
                      setErrorMessage({ ...errorMessage, CONTACT_MOBILE1: '' })
                      setPhoneNumber1(event.target.value)
                    }
                  } else if (index === 1) {
                    if (event.target.value.length <= 10) {
                      setErrorMessage({ ...errorMessage, CONTACT_MOBILE2: '' })
                      setPhoneNumber2(event.target.value)
                    }
                  } else {
                    if (event.target.value.length <= 10) {
                      setErrorMessage({ ...errorMessage, CONTACT_MOBILE3: '' })
                      setPhoneNumber3(event.target.value)
                    }
                  }
                }}
                type="number"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                required
                heading="เบอร์โทรศัพท์"
                helperText={(index === 0 ? errorMessage.CONTACT_MOBILE1 : index === 1 ? errorMessage.CONTACT_MOBILE2 : errorMessage.CONTACT_MOBILE3)}

              />
            </Col>
            <Col xs={12} md={3} className="pt-2">
              <InputTextField
                placeholder={'กรอกชื่อ'}
                value={(index === 0 ? firstName1 : index === 1 ? firstName2 : firstName3)}
                // onBlur={(event: any) => {
                //   if (index === 0 && firstName1 !== '') {
                //     amplitudeTrack('4_Contact_P_firstname1')
                //   } else if (index === 1 && firstName2 !== '') {
                //     amplitudeTrack('4_Contact_P_firstname2')
                //   } else {
                //     if (firstName3 !== '') {
                //       amplitudeTrack('4_Contact_P_firstname3')
                //     }
                //   }
                // }}
                onchange={(event: any) => {
                  if (index === 0) {
                    if (checkThaiLang(event.target.value)) {
                      setFirstName1(event.target.value)
                      setErrorMessage({ ...errorMessage, CONTACT_NAME1: '' })
                    } else if (event.target.value === '') {
                      setFirstName1(event.target.value)
                    }
                  } else if (index === 1) {
                    if (checkThaiLang(event.target.value)) {
                      setFirstName2(event.target.value)
                      setErrorMessage({ ...errorMessage, CONTACT_NAME2: '' })
                    } else if (event.target.value === '') {
                      setFirstName2(event.target.value)
                    }
                  } else {
                    if (checkThaiLang(event.target.value)) {
                      setFirstName3(event.target.value)
                      setErrorMessage({ ...errorMessage, CONTACT_NAME3: '' })
                    } else if (event.target.value === '') {
                      setFirstName3(event.target.value)
                    }
                  }
                }}
                required
                heading="ชื่อ"
                helperText={(index === 0 ? errorMessage.CONTACT_NAME1 : index === 1 ? errorMessage.CONTACT_NAME2 : errorMessage.CONTACT_NAME3)}

              />
            </Col>
            <Col xs={12} md={3} className="pt-2">
              <InputTextField
                placeholder={'กรอกนามสกุล'}
                value={(index === 0 ? lastName1 : index === 1 ? lastName2 : lastName3)}
                // onBlur={() => {
                //   if (index === 0 && lastName1 !== '') {
                //     amplitudeTrack('4_Contact_P_lastname1')
                //   } else if (index === 1 && lastName2 !== '') {
                //     amplitudeTrack('4_Contact_P_lastname2')
                //   } else {
                //     if (lastName3 !== '') {
                //       amplitudeTrack('4_Contact_P_lastname3')
                //     }
                //   }
                // }}
                onchange={(event: any) => {
                  if (index === 0) {
                    if (checkThaiLang(event.target.value)) {
                      setLastName1(event.target.value)
                      setErrorMessage({ ...errorMessage, CONTACT_SURNAME1: '' })
                    } else if (event.target.value === '') {
                      setLastName1(event.target.value)
                    }
                  } else if (index === 1) {
                    if (checkThaiLang(event.target.value)) {
                      setLastName2(event.target.value)
                      setErrorMessage({ ...errorMessage, CONTACT_SURNAME2: '' })
                    } else if (event.target.value === '') {
                      setLastName2(event.target.value)
                    }
                  } else {
                    if (checkThaiLang(event.target.value)) {
                      setLastName3(event.target.value)
                      setErrorMessage({ ...errorMessage, CONTACT_SURNAME3: '' })
                    } else if (event.target.value === '') {
                      setLastName3(event.target.value)
                    }
                  }
                }
                }
                heading="นามสกุล"
                helperText={(index === 0 ? errorMessage.CONTACT_SURNAME1 : index === 1 ? errorMessage.CONTACT_SURNAME2 : errorMessage.CONTACT_SURNAME3)}
                required
              />
            </Col>
          </Styles.MarginRow>
        )
      })}


      <Styles.MarginRow>
        <div className="pt-2"><span>ท่านรู้จัก Ufriend จาก</span><span style={{ color: colors.danger }}>*</span></div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          row
          value={knownUfriendFrom?.value}
          onChange={(e: any) => {
            setErrorMessage({ ...errorMessage, CONTACT_UKONW: '' })
            setKnownUfriendFrom({ value: e.target.value, label: _.find(knownUfriendFromData, (data: any) => data.value === e.target.value)?.label || '' })
            // if (e.target.value === 'TIKTOK') {
            //   amplitudeTrack('4_Contact_P_tiktok')
            // } else if (e.target.value === 'FACEBOOK') {
            //   amplitudeTrack('4_Contact_P_fb')
            // } else if (e.target.value === 'SEARCH_GOOGLE') {
            //   amplitudeTrack('4_Contact_P_search')
            // } else if (e.target.value === 'STOREFRONT_SIGN') {
            //   amplitudeTrack('4_Contact_P_store')
            // } else if (e.target.value === 'LINE') {
            //   amplitudeTrack('4_Contact_P_line')
            // } else if (e.target.value === 'FRIEND_REC') {
            //   amplitudeTrack('4_Contact_P_friend')
            // } else if (e.target.value === 'CAR_ADVERTISE') {
            //   amplitudeTrack('4_Contact_P_car')
            // } else {
            //   amplitudeTrack('4_Contact_P_ig')
            // }
          }}
        >
          {knownUfriendFromData && (
            knownUfriendFromData.map((data: any) => {
              return (
                <Col xs={12} md={2} className="pt-2">
                  <FormControlLabel
                    value={data.value}
                    control={
                      <Radio
                        sx={(theme) => ({
                          '& .MuiSvgIcon-root': {
                            [theme.breakpoints.down('xl')]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down('lg')]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down('md')]: {
                              fontSize: 30,
                            }
                          },
                        })}
                      />}
                    label={data.label}
                  />
                </Col>
              )
            })
          )}
        </RadioGroup>
        {errorMessage.CONTACT_UKONW !== '' &&
          <div className='pt-2'>
            <HelperText label={errorMessage.CONTACT_UKONW} />
          </div>
        }
      </Styles.MarginRow>

      <Styles.MarginRow>
        <Col className="pt-2">
          <InputTextarea
            // onBlur={() => { if (otherDetial !== '') { amplitudeTrack('4_Contact_P_other') } }} 
            placeholder={'รายละเอียดอื่นๆ'} onchange={(event: any) => { setOtherDetial(event.target.value) }} value={otherDetial} />
        </Col>
      </Styles.MarginRow>

      <FormGroup className="d-flex flex-column pt-2">
        {isShowAccpetCheckBox &&
          <FormControlLabel control={
            <Checkbox checked={accpetCondition || false}
              onChange={(e: any) => (setAccpetCondition(e.target.checked))}
            />
          }
            label="ยอมรับเงื่อนไขลูกค้าต้องอายุ 20 - 60 ปี ณ วันที่ทำสัญญา"
            required />
        }
        <div className="d-flex">
          <FormControlLabel
            control={<Checkbox checked={condition || false}
              onChange={(e: any) => (setCondition(e.target.checked))}
            />}
            label="ยอมรับเงื่อนไขข้อตกลง" />
          <div style={{ color: colors.themeMainColor, cursor: 'pointer', marginBlock: 'auto', textDecoration: 'underline' }}
            onClick={() => {
              setIsShowMoalPdpa(true)
              if (!checkIsShowModalPdpa) {
                amplitudeTrack('4_Contact_P_policy')
              }
            }}>
            นโยบายความเป็นส่วนตัว
          </div>
        </div>
      </FormGroup>

      <Styles.MarginRow>
        <Col>
          <ButtonCustom
            variant={'outline'}
            btnStyle={{ width: '100%' }}
            textButton={"ย้อนกลับ"}
            onClick={() => {
              onSubmit('BACK')
            }}
          />
        </Col>
        <Col>
          {isTurnType && (
            <div className={'Mui-refinance'}>
              <ButtonCustom
                className={'Mui-refinance'}
                btnStyle={{ width: '100%' }}
                textButton={"ลงทะเบียน"}
                disabled={
                  isShowAccpetCheckBox ? !accpetCondition || !condition : !condition
                }
                onClick={() => {
                  onCheckData()
                }}
              />
            </div>
          ) || (
              <div className={'Mui-installment'}>
                <ButtonCustom
                  className={'Mui-installment'}
                  btnStyle={{ width: '100%' }}
                  textButton={"ลงทะเบียน"}
                  disabled={
                    isShowAccpetCheckBox ? !accpetCondition || !condition : !condition
                  }
                  onClick={() => {
                    onCheckData()
                  }}
                />
              </div>
            )}
        </Col>
      </Styles.MarginRow>
      <ModalCustom
        size="lg"
        title={'คำเตือน'}
        modalTheme="default"
        onCancel={() => {
          amplitudeTrack('5_Warn_B_cancel')
          setIsShowMoal(false)
        }}
        onSubmit={() => { onSubmit('') }}
        onClose={() => {
          amplitudeTrack('5_Warn_B_x')
          setIsShowMoal(false)
        }}
        textCancel={'ยกเลิก'}
        textBtnConfirm={'ยืนยัน'}
        isShowProps={isShowModal}
        closeButton
        disabledSubmit={!conditionModal}
        footerInline
        component={
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <img height="auto" width="40%" src={imgs.warningAlert} />
            </div>
            <FormGroup className="d-flex flex-column">
              <FormControlLabel
                control={
                  <Checkbox checked={conditionModal || false}
                    onChange={(e: any) => {
                      setConditionModal(e.target.checked)
                      // if (!conditionModal) amplitudeTrack('5_Warn_P_agreement')
                    }}
                  />}
                label="ข้าพเจ้ารับทราบว่าการรับจ้างทำสัญญา หรือยินยอมทำสัญญาแทนผู้อื่น มีโทษทางกฎหมาย ทั้งทางคดีอาญาและทางแพ่ง" />
            </FormGroup>
          </>
        }
      />

      <ModalCustom
        size="xl"
        title={''}
        modalTheme="default"
        onSubmit={() => { setIsShowMoalPdpa(false) }}
        onClose={() => { setIsShowMoalPdpa(false) }}
        textBtnConfirm={'ยืนยัน'}
        isShowProps={!!pdpaData && isShowModalPdpa}
        closeButton
        component={<div style={{ maxHeight: '40vh', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: pdpaData }} />}
        // component={<>{PDPA}</>}
      />
    </Styles.Container>
  );
}
