import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import OptionsApi from "../../../api/option/option.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import { FromRegister, Product } from "../type-register";
import * as Styles from "./layout-estimate.style";
import { EstimateCollapsible } from "./components";
import { REGISTER_TYPE_TH, REGISTER_TYPE } from "../../../utils/enum";
import { colors } from "../../../constants/colors";
import Loading from "../../../components/loading/loading";
import { RESPONSIVE_FULL, RESPONSIVE_HALF } from "../constants/constants";
import AuthenApi from "../../../api/authen/authen.api";
import { swalError } from "../../../components/notification/swal";

const { TURN1, TURN2, SECONDHAND } = REGISTER_TYPE;

const initStateErrorMessage = {
  PRODUCT_MODEL: ``,
  PRODUCT_STORAGE: ``,
  PRODUCT_COLOR: ``,
};

const ININT_PROD = { code: "", text: "" };

interface props {
  data?: any;
  onChange?: (value: any) => void;
  onClickNext: () => void;
  estimateHeader: any;
  setEstimateHeader: (value: any) => void;
}
export default function LayoutEstimate(props: props) {
  const { estimateHeader, setEstimateHeader } = props;

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [data, setData] = useState<FromRegister>(props.data);
  const [type, setType] = useState<string>(props.data?.type || TURN1);
  const [productGroup, setProductGroup] = useState<string>(props.data?.productGroup || "iPhone");
  const [productModel, setProductModel] = useState<any[]>([]);
  const [product, setProduct] = useState<Product>(props.data?.product?.model || { ...ININT_PROD });
  const [storageModel, setStorageModel] = useState<any[]>([]);
  const [storage, setStorage] = useState<Product>(props.data?.product?.storage || { ...ININT_PROD });
  const [colorModel, setColorModel] = useState<any[]>([]);
  const [color, setColor] = useState<Product>(props.data?.product?.color || { ...ININT_PROD });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [estimateData, setEstimateData] = useState<any>(props.data?.estimate || {});
  const [estimateValue, setEstimateValue] = useState<any>(props.data?.estimateValue || []);
  const [expanded, setExpanded] = useState<number[]>([0]);
  const [optionEstimate, seteOptionEstimate] = useState<any[]>([]);
  const [productCode, setProductCode] = useState<string>(
    props.data?.productTurn?.code || props.data?.product?.code || ""
  );

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const PARAMS_CATEGORY = params.get("category");
  const PARAMS_MODEL = params.get("model");
  const PARAMS_STORAGE = params.get("storage");
  const PARAMS_COLOR = params.get("color");

  // Loading API
  const [optionEstimateLoading, setOptionEstimateLoading] = useState(false);
  const [getConditionLoading, setGetConditionLoading] = useState(false);

  const loadEstimateDevice = useCallback(() => {
    setOptionEstimateLoading(true);
    OptionsApi.estimateDevice()
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.result || [];
          seteOptionEstimate(result);
        }
      })
      .finally(() => {
        setOptionEstimateLoading(false);
      });
  }, []);

  useEffect(() => {
    loadEstimateDevice();
  }, [loadEstimateDevice]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(data);
    }
  }, [data]);

  useEffect(() => {
    let group;
    switch (PARAMS_CATEGORY) {
      case "Smartphone":
        group = "iPhone";
        break;
      case "Tablet":
        group = "iPad";
        break;
      default:
        break;
    }

    if (PARAMS_MODEL && !props.data?.product?.model?.code && !_.isEmpty(productModel)) {
      const product = _.find(productModel, (val: any) => val.label === PARAMS_MODEL) || { ...ININT_PROD };
      setProduct({ code: product?.value, text: product?.label });
    }
    if (PARAMS_STORAGE && !props.data?.product?.storage?.code && !_.isEmpty(storageModel)) {
      const storage = _.find(storageModel, (val: any) => val.label === PARAMS_STORAGE) || { ...ININT_PROD };
      setStorage({ code: storage?.value, text: storage?.label });
    }
    if (PARAMS_COLOR && !props.data?.product?.color?.code && !_.isEmpty(colorModel)) {
      const color = _.find(colorModel, (val: any) => val.label === PARAMS_COLOR) || { ...ININT_PROD };
      setColor({ code: color?.value, text: color?.label });
    }
    if (PARAMS_CATEGORY && !props.data?.product?.productGroup) {
      setProductGroup(group || "iPhone");
    }
  }, [PARAMS_CATEGORY, PARAMS_MODEL, PARAMS_STORAGE, PARAMS_COLOR, productModel, storageModel, colorModel]);

  const loadProduct = useCallback(() => {
    let condition: any = {};
    if (type) condition = { ...condition, type: TURN1 };
    if (productGroup) condition = { ...condition, category: productGroup === "iPhone" ? "Smartphone" : "Tablet" };

    OptionsApi.productModel(condition).then((res) => {
      if (res.status === 200) {
        setProductModel(res.data.model);
      }
    });
  }, [type, productGroup]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  useEffect(() => {
    if (product.code) {
      let condition: any = {};
      if (type) condition = { ...condition, type: TURN1 };
      if (product.text) condition = { ...condition, model: product.text };
      OptionsApi.productStorage(condition).then((res) => {
        if (res.status === 200) {
          setStorageModel(res.data.storage);
        }
      });
    }
  }, [product.code]);

  useEffect(() => {
    if (type && product.code && storage.code) {
      let condition: any = {};
      if (type) condition = { ...condition, type: TURN1 };
      if (product) condition = { ...condition, model: product.text };
      if (storage) condition = { ...condition, storage: storage.text };
      OptionsApi.productColor(condition).then((res) => {
        if (res.status === 200) {
          setColorModel(res.data.color);
        }
      });
    }
  }, [storage.code]);

  useEffect(() => {
    if (product.code && storage.code && color.code) {
      const condition: any = {
        type,
        model: product.text,
        storage: storage.text,
        color: color.text,
      };
      OptionsApi.getProductCode(condition)
        .then((res) => {
          if (res.status === 200) {
            setProductCode(res?.data?.product_code);
          }
        })
        .catch(() => {
          setProductCode("");
        });
    }
  }, [color.code]);

  const onSubmit = async (submitType: REGISTER_TYPE) => {
    try {
      if (!product.code || !storage.code || !color.code) {
        const msg: any = {
          PRODUCT_MODEL: !product.code ? "โปรดเลือกสินค้า" : "",
          PRODUCT_STORAGE: !storage.code ? "โปรดเลือกความจุ" : "",
          PRODUCT_COLOR: !color.code ? "โปรดเลือกสี" : "",
        };
        setErrorMessage({ ...initStateErrorMessage, ...msg });
        return false;
      } else {
        setGetConditionLoading(true);

        const setDt: any = _.omit(
          {
            ...data,
            type: submitType,
            estimate: estimateData,
            estimateValue: estimateValue,
          },
          ["productTurn"]
        );

        switch (submitType) {
          case TURN1:
          case SECONDHAND:
            setDt["product"] = {
              code: productCode,
              model: product,
              storage: storage,
              color: color,
            };
            setDt["productGroup"] = productGroup;
            break;
          case TURN2:
            setDt["productTurn"] = {
              code: props.data?.productTurn?.code || productCode || "",
              model: props.data?.productTurn?.product || product || "",
              storage: props.data?.productTurn?.storage || storage || "",
              color: props.data?.productTurn?.color || color || "",
            };

            setDt["product"] = {
              code: "",
              model: "",
              storage: "",
              color: "",
            };
            setDt["productGroup"] = "iPhone";

            break;
          default:
            break;
        }

        const condition: any = {
          productCode: productCode,
          estimate: _.uniqBy(estimateValue, "code"),
        };

        const res = await AuthenApi.conditionMaxprice(condition);

        if (res.status === 200) {
          setDt["condition"] = res?.data?.condition || "";
          setDt["maximum"] = res?.data?.maximun || 0;

          setData(setDt);
          setTimeout(() => {
            return props.onClickNext && props.onClickNext();
          }, 500);
        }
      }
    } catch (error) {
      swalError("โปรดลองใหม่อีกครั้ง หรือ ติดต่อแอดมิน");
    } finally {
      setGetConditionLoading(false);
    }
  };

  const clearData = () => {
    setErrorMessage(initStateErrorMessage);
    setProductGroup("iPhone");
    setProductModel([]);
    setProduct({ ...ININT_PROD });
    setStorageModel([]);
    setStorage({ ...ININT_PROD });
    setColorModel([]);
    setColor({ ...ININT_PROD });
    setEstimateData([]);
    setExpanded([0]);
    setProduct({ ...ININT_PROD });
  };

  const clearDataProductGroup = () => {
    setErrorMessage(initStateErrorMessage);
    setProductModel([]);
    setProduct({ ...ININT_PROD });
    setStorageModel([]);
    setStorage({ ...ININT_PROD });
    setColorModel([]);
    setColor({ ...ININT_PROD });
    setEstimateData([]);
    setExpanded([0]);
  };

  const clearDataProduct = () => {
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setStorageModel([]);
    setColorModel([]);
  };

  const clearDataStorage = () => {
    setColor({ ...ININT_PROD });
    setColorModel([]);
  };

  const onRegisterTypeChange = (type: string) => {
    const setDt = _.omit(
      {
        ...data,
        type: type,
        product: { code: "", model: { ...ININT_PROD }, storage: { ...ININT_PROD }, color: { ...ININT_PROD } },
        productHand: "NEW",
        downPayment: "0",
        installment: 0,
        installments: 0,
        period: 0,
        interestedBranch: "",
        friend_advisor_code: "",
        profile: _.omit(data.profile, ["serial_number", "iphoneStorage", "imeiImages"]),
      },
      ["estimate", "productTurn", "appointmentDate", "combo", "discountPeriod", "coupon", "condition", "maximum"]
    );

    clearData();
    setType(type);
    setData(setDt);
    setEstimateHeader({});
  };

  return (
    <Styles.Container>
      <Loading show={optionEstimateLoading || getConditionLoading} type="fullLoading" />
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        row
        value={type}
        onChange={(e: any) => onRegisterTypeChange(e?.target?.value)}
        className="justify-content-center"
      >
        <Row className="d-flex justify-content-center align-items-center">
          {Object.entries(REGISTER_TYPE_TH).map(([key, name]) => (
            <Styles.ColType key={key} className="text-center">
              <Styles.TypeBox>
                <FormControlLabel
                  value={key}
                  control={
                    <Radio
                      sx={(theme) => ({
                        "& .MuiSvgIcon-root": {
                          [theme.breakpoints.down("xl")]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down("lg")]: {
                            fontSize: 24,
                          },
                          [theme.breakpoints.down("md")]: {
                            fontSize: 30,
                          },
                        },
                      })}
                    />
                  }
                  label={<div className="font-22 font-extra-bold">{name}</div>}
                />
              </Styles.TypeBox>
            </Styles.ColType>
          ))}
        </Row>
      </RadioGroup>

      <Typography variant="h6" style={{ color: colors.themeMainColor }} mb={2} mt={2}>
        เลือกสินค้าเครื่องปัจจุบันของคุณ
      </Typography>

      <Styles.MarginRow>
        <Col {...RESPONSIVE_FULL}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            row
            value={productGroup}
            onChange={(e: any) => {
              clearDataProductGroup();
              setProductGroup(e.target.value);
            }}
          >
            <Col {...RESPONSIVE_HALF}>
              <FormControlLabel
                value="iPhone"
                control={
                  <Radio
                    sx={(theme) => ({
                      "& .MuiSvgIcon-root": {
                        [theme.breakpoints.down("xl")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("lg")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("md")]: {
                          fontSize: 30,
                        },
                      },
                    })}
                  />
                }
                label="iPhone"
              />
            </Col>

            <Col {...RESPONSIVE_HALF}>
              <FormControlLabel
                value="iPad"
                control={
                  <Radio
                    sx={(theme) => ({
                      "& .MuiSvgIcon-root": {
                        [theme.breakpoints.down("xl")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("lg")]: {
                          fontSize: 24,
                        },
                        [theme.breakpoints.down("md")]: {
                          fontSize: 30,
                        },
                      },
                    })}
                  />
                }
                label="iPad"
              />
            </Col>
          </RadioGroup>
          <Styles.MarginRow>
            <AutocompleteSelect
              options={productModel || []}
              value={_.find(productModel, (val: any) => val.value === product.code) || null}
              placeholder={"เลือกสินค้า"}
              getOptionLabel={(option: any) => option.label}
              labelId="product"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_MODEL: "" });
                  setProduct({ code: value.value, text: value.label });
                  clearDataProduct();
                } else if (reason === "clear") {
                  setProduct({ ...ININT_PROD });
                  clearDataProduct();
                }
              }}
              heading="สินค้า"
              helperText={errorMessage.PRODUCT_MODEL}
              required
            />
          </Styles.MarginRow>
          <Row>
            <Col {...RESPONSIVE_HALF} className="pt-2">
              <AutocompleteSelect
                options={storageModel || []}
                value={_.find(storageModel, (val: any) => val.value === storage.code) || null}
                placeholder={"เลือกความจุ"}
                getOptionLabel={(option: any) => option.label}
                labelId="storage"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_STORAGE: "" });
                    setStorage({ code: value.value, text: value.label });
                    clearDataStorage();
                  } else if (reason === "clear") {
                    setStorage({ ...ININT_PROD });
                    clearDataStorage();
                  }
                }}
                heading="ความจุ"
                required
                helperText={errorMessage.PRODUCT_STORAGE}
              />
            </Col>
            <Col {...RESPONSIVE_HALF} className="pt-2">
              <AutocompleteSelect
                options={colorModel || []}
                value={_.find(colorModel, (val: any) => val.value === color.code) || null}
                placeholder={"เลือกสี"}
                getOptionLabel={(option: any) => option.label}
                labelId="color"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_COLOR: "" });
                    setColor({ code: value.value, text: value.label });
                  } else if (reason === "clear") {
                    setColor({ ...ININT_PROD });
                  }
                }}
                heading="สี"
                required
                helperText={errorMessage.PRODUCT_COLOR}
              />
            </Col>
          </Row>

          <Row>
            <Col {...RESPONSIVE_FULL} className="pt-2">
              <EstimateCollapsible
                optionEstimate={optionEstimate}
                setDisableSubmit={setDisableSubmit}
                estimateData={estimateData}
                setEstimateData={setEstimateData}
                expanded={expanded}
                setExpanded={setExpanded}
                estimateHeader={estimateHeader}
                setEstimateHeader={setEstimateHeader}
                estimateValue={estimateValue}
                setEstimateValue={setEstimateValue}
              />
            </Col>
          </Row>

          {data?.type === TURN1 ? (
            <Styles.MarginRow>
              <Col className="pt-2">
                <ButtonCustom
                  btnStyle={{
                    fontWeight: "bold",
                    borderRadius: "8px",
                    width: "100%",
                    padding: "10px 16px !important",
                  }}
                  className={"btn-vividAzure mt-2"}
                  variant={"outlined"}
                  textButton={"เทิร์นเครื่องเก่ารับเครื่องใหม่"}
                  disabled={disableSubmit}
                  onClick={() => {
                    onSubmit(TURN2);
                  }}
                />
              </Col>
              <Col className="pt-2">
                <ButtonCustom
                  btnStyle={{
                    fontWeight: "bold",
                    borderRadius: "8px",
                    width: "100%",
                    padding: "10px 16px !important",
                  }}
                  className={"btn-darkBlue mt-2"}
                  variant={"outlined"}
                  textButton={"เทิร์นเครื่องเก่ารับเครื่องเดิม (แลกเงิน)"}
                  disabled={disableSubmit}
                  onClick={() => {
                    onSubmit(TURN1);
                  }}
                />
              </Col>
            </Styles.MarginRow>
          ) : null}

          {data?.type === SECONDHAND ? (
            <Styles.CenterContainer>
              <ButtonCustom
                btnStyle={{ width: "100%" }}
                className={"mt-2"}
                textButton={"ยืนยัน"}
                disabled={disableSubmit}
                onClick={() => {
                  onSubmit(SECONDHAND);
                }}
              />
            </Styles.CenterContainer>
          ) : null}
        </Col>
      </Styles.MarginRow>
    </Styles.Container>
  );
}
