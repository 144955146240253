import { colors } from "../../../../constants/colors";
import * as Style from "./style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from 'react-player'
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight, faChevronCircleRight, faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Box, styled } from "@mui/material";
import { imagesInstallment, imgs } from "../../../../constants/images";
import _ from "lodash";
function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", color: colors.yellow, }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", color: colors.yellow, }}
            onClick={onClick}
        />
    );
}

export const Slicker = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: colors.white,
    [theme.breakpoints.down('md')]: {
        height: '100%',
        padding: '0px 0px 20px 0px',
    }
}))

interface review {
    type?: string
}

const doubleLeft = faChevronCircleLeft as IconProp;
const doubleRight = faChevronCircleRight as IconProp;
export default function Review(props: review) {
    const sliderRef: any = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        arrow: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        // autoplay: true,
        // autoplaySpeed: 5000,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    const [currentIndex, setCurrentIndex] = useState<number>(0);

    // เมื่อวิดีโอจบ (onEnded) ให้ขยับไปวิดีโอถัดไป
    const handleEnded = () => {
        const videos = props.type === 'INSTALLMENT' ? videoInstallment : videoRefinance
        if (currentIndex < videos.length - 1) {
            setCurrentIndex(currentIndex + 1);
            sliderRef.current?.slickGoTo(currentIndex + 1);
        }
    };

    const videoRefinance = [
        'https://www.youtube.com/embed/miBrZ19CQp0',
        'https://www.youtube.com/embed/JkQ8yX-Oi8w',
        'https://www.youtube.com/embed/lmpDkE3vAlw',
        'https://www.youtube.com/embed/3DEeV7j9M7Y',

        // 'https://www.youtube.com/embed/JEYjNgsKjd8',
        // 'https://www.youtube.com/embed/KHvdNs0dhmg',
        // 'https://www.youtube.com/embed/eIzJokGlaZM',
        // 'https://www.youtube.com/embed/JkQ8yX-Oi8w',
        // 'https://www.youtube.com/embed/dVg651RlYSI',
        // 'https://www.youtube.com/embed/miBrZ19CQp0',
        // 'https://www.youtube.com/embed/QZwPxOcLHIE',
        // 'https://www.youtube.com/embed/eMjBXd14rMc'
    ]

    const videoInstallment = [
        'https://www.youtube.com/embed/eMjBXd14rMc',
        'https://www.youtube.com/embed/OOtMxR6UYAw',
        'https://www.youtube.com/embed/U6WwzFhBA8o',
        'https://www.youtube.com/embed/ggj_ioFjv2w',
        'https://www.youtube.com/embed/UPwRswVHjK4',
        'https://www.youtube.com/embed/WS_2EtaZ7A4',
    ]

    return (
        <Box className="slider-container px-5"
            style={{
                margin: 'auto',
                // backgroundImage: `url(${imagesInstallment.UFW1_10})`,
                // backgroundSize: 'cover',
                // backgroundPosition: 'center',
                // backgroundRepeat: 'no-repeat',
            }}
            sx={{ paddingLeft: { xl: '200px !important' }, paddingRight: { xl: '200px !important' } }}>


            <Slicker>
                <FontAwesomeIcon
                    style={{ zIndex: 20, cursor: 'pointer', fontSize: 30 }}
                    icon={doubleLeft}
                    color={colors.themeMainColor}
                    onClick={() => sliderRef?.current?.slickPrev()}
                />
                <Slider {...settings} ref={sliderRef} className="w-100">
                    {_.map(props.type === 'INSTALLMENT' ? videoInstallment : videoRefinance, (item, index) => {
                        return (
                            <Box >
                                <ReactPlayer
                                    url={item} // เปลี่ยนเป็น Shorts ที่ต้องการ
                                    // width="228px"
                                    // height="400px"
                                    width="300px"
                                    height="500px"
                                    playing={index === currentIndex}
                                    controls={false}
                                    loop={false}
                                    muted={true}
                                    playsinline
                                    frameBorder='0'
                                    onEnded={handleEnded}
                                    style={{ objectFit: "cover", border: 'none', margin: 'auto' }}
                                    config={{
                                        youtube: {
                                            embedOptions: {
                                                frameBorder: '0'
                                            }
                                        }
                                    }}
                                />
                            </Box>

                        )
                    })}
                </Slider>
                <FontAwesomeIcon
                    style={{ zIndex: 20, cursor: 'pointer', fontSize: 30 }}
                    icon={doubleRight}
                    color={colors.themeMainColor}
                    onClick={() => sliderRef?.current?.slickNext()} />
            </Slicker>
        </Box>
    );
}