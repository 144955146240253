import { defaultMenu } from "../constants/menu";
import Branch from "../pages/branch/branch";
import Register from "../pages/register/register";
import { routeName } from "./routers-name";
import CampaignFilmFocus from "../pages/campaign/filmfocus/FilmFocus";
import Refinance from "../pages/landingpage/refinance/refinance";
import Installment from "../pages/landingpage/installment/installment";
import ShopLink from "../pages/shoplink/shoplink";

const routes = [
  {
    key: defaultMenu.REGISTER.key,
    name: defaultMenu.REGISTER.name,
    textName: defaultMenu.REGISTER.textName,
    component: Register,
    path: routeName.register,
    header: true,
    footer: true,
    container: true,
  },
  {
    key: defaultMenu.NEAR_BRANCH.key,
    name: defaultMenu.NEAR_BRANCH.name,
    textName: defaultMenu.NEAR_BRANCH.textName,
    component: Branch,
    path: routeName.nearBranch,
    header: true,
    footer: true,
    container: true,
  },
  {
    key: defaultMenu.CAMPAIGN_FILM_FOCUS.key,
    name: defaultMenu.CAMPAIGN_FILM_FOCUS.name,
    textName: defaultMenu.CAMPAIGN_FILM_FOCUS.textName,
    component: CampaignFilmFocus,
    path: routeName.campaignFilmFocus,
    header: false,
    footer: true,
    container: false,
  },
  {
    key: defaultMenu.REFINANCE.key,
    name: defaultMenu.REFINANCE.name,
    textName: defaultMenu.REFINANCE.textName,
    component: Refinance,
    path: routeName.refinance,
    header: false,
    footer: false,
    container: false,
  },
  {
    key: defaultMenu.INSTALLMENT.key,
    name: defaultMenu.INSTALLMENT.name,
    textName: defaultMenu.INSTALLMENT.textName,
    component: Installment,
    path: routeName.installment,
    header: false,
    footer: false,
    container: false,
  },
  {
    key: defaultMenu.SHOPLINK.key,
    name: defaultMenu.SHOPLINK.name,
    textName: defaultMenu.SHOPLINK.textName,
    component: ShopLink,
    path: routeName.ufriendShoplink,
    header: false,
    footer: false,
    container: false,
  },



  {
    key: defaultMenu.REFINANCE_DEV.key,
    name: defaultMenu.REFINANCE_DEV.name,
    textName: defaultMenu.REFINANCE_DEV.textName,
    component: Refinance,
    path: routeName.reDevTest,
    header: false,
    footer: false,
    container: false,
  },
  {
    key: defaultMenu.INSTALLMENT_DEV.key,
    name: defaultMenu.INSTALLMENT_DEV.name,
    textName: defaultMenu.INSTALLMENT_DEV.textName,
    component: Installment,
    path: routeName.inDevTest,
    header: false,
    footer: false,
    container: false,
  },

  
];

export default routes;
