import { routeName } from "../routes/routers-name";

export const defaultMenu: any = {
    REGISTER: { key: 'REGISTER', name: 'Register', textName: 'Register', path: routeName.register },
    NEAR_BRANCH: { key: 'NEAR_BRANCH', name: 'NearBranch', textName: 'NearBranch', path: routeName.nearBranch },
    CAMPAIGN_FILM_FOCUS: { key: 'CAMPAIGN_FILM_FOCUS', name: 'CampaignFilmFocus', textName: 'CampaignFilmFocus', path: routeName.campaignFilmFocus },
    REFINANCE: { key: 'REFINANCE', name: 'Refinance', textName: 'Refinance', path: routeName.refinance },
    INSTALLMENT: { key: 'INSTALLMENT', name: 'Installment', textName: 'Installment', path: routeName.installment },
    SHOPLINK: { key: 'SHOPLINK', name: 'ShopLink', textName: 'ShopLink', path: routeName.ufriendShoplink },



    REFINANCE_DEV: { key: 'REFINANCE', name: 'Refinance', textName: 'Refinance', path: routeName.refinance },
    INSTALLMENT_DEV: { key: 'INSTALLMENT', name: 'Installment', textName: 'Installment', path: routeName.installment },
}


