import SearchIcon from "@mui/icons-material/Search";
import {
  CardContent,
  InputAdornment,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Card,
  Avatar,
} from "@mui/material";
import {
  GoogleMap,
  MarkerF,
  InfoWindowF,
  useLoadScript,
} from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AuthenApi from "../../../../api/authen/authen.api";
import GoogleMapApi from "../../../../api/google-map/googleMap";
import InputTextField from "../../../../components/input/inputTextField";
import { swalError } from "../../../../components/notification/swal";
import { colors } from "../../../../constants/colors";
import "../../../../styles/googleMap.css";
import * as Style from "./branch.style";
// import { refinanceCard } from "./config"; // if you need your refinance cards

// interface BranchProps {
//   type: string
// }
export default function Branch() {
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState({
    id: 0,
    address: "",
    location_url: "",
  });

  const [optionBranchLocation, setOptionBranchLocation] = useState<any[]>([]);
  const [zoomLevel, setZoomLevel] = useState<number>(14);
  const [valueSearch, setValueSearch] = useState<string>("");

  const infoData = useRef({ id: 0, address: "" });
  const [tab, setTab] = useState<string>("กรุงเทพและปริมณฑล");
  const [tabValue, setTabValue] = useState<string>("BKK");
  const [cardData, setCardData] = useState<any[]>([]);

  // All markers fetched from the backend
  const [markers, setMarkers] = useState<any[]>([]);
  // Filtered by search text
  const [filteredList, setFilteredList] = useState<any[]>([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB-5XYOoU_k99QzqMX7C9WhvcVCKOPFU6k",
    // optional: libraries: ["places"]
  });

  const onFetchData = useCallback(async () => {
    try {
      const condition: any = {
        type: tab === "กรุงเทพและปริมณฑล" ? "BKK" : "OTHER",
      };
      const res = await AuthenApi.cardLocation(condition);
      if (res.status === 200) {
        setCardData(res.data.result);
      }
    } catch (err) {
      console.error(err);
    }
  }, [tab]);

  useEffect(() => {
    onFetchBranchLocation();
    onFetchBranchRegion("BKK");
  }, []);

  useEffect(() => {
    // Trigger fetch each time tab changes
    if (tab === "กรุงเทพและปริมณฑล") {
      setTabValue("BKK");
      onFetchData();
    } else {
      setTabValue("OTHER");
      onFetchData();
    }
  }, [tab, onFetchData]);

  useEffect(() => {
    // Filter the location list whenever the search value changes
    setFilteredList(onSearch(optionBranchLocation));
  }, [valueSearch, optionBranchLocation]);

  // Helper to cut string
  const cutString = (value: string) => {
    let result = "";
    if (value.includes("Apple House")) {
      let cut = value.split("Apple House ");
      result = cut[1];
    } else if (value.includes("Future Park")) {
      let cut = value.split("Future Park ");
      result = cut[1];
    } else {
      result = value;
    }
    return result;
  };

  const onFetchBranchLocation = async () => {
    try {
      const obj = { branchName: "" };
      const res = await GoogleMapApi.getLocation(obj);
      const { success, result } = res.data;
      if (success) {
        const data = result
          .filter(
            (el: any) =>
              ![
                "ON_HOLD",
                "IT",
                "QC",
                "Store",
                "Service",
                "Refurbished",
                "AWG",
                "APK",
                "ACW",
                "ALP",
                "ASC",
                "ANW",
                "ASR",
                "ACB",
                "ACT",
              ].includes(el.value)
          )
          .filter(
            (el: any) =>
              el.location.lat !== null && el.location.long !== null
          )
          .map((el: any, i: number) => {
            return {
              ...el,
              true_value: el.value,
              value: el.label,
              index: i,
              title: `Ufriend สาขา ${cutString(el.label)}`,
              address: `สาขา ${el.label}`,
              location_url: el.location.location_url || "",
              lat: Number(el.location.lat) || 0,
              lng: Number(el.location.long) || 0,
            };
          });

        // Build marker array
        const markerLocation = data.map((el: any) => ({
          lat: el.lat,
          lng: el.lng,
          place: el.true_value,
          type: el.type || "",
          location_url: el.location_url,
          address: el.address,
          index: el.index,
        }));

        setOptionBranchLocation(data);
        setFilteredList(data);
        setMarkers(markerLocation);
      }
    } catch (error: any) {
      swalError(error.message);
    }
  };

  const onFetchBranchRegion = async (value: string) => {
    try {
      let obj = {
        type: value || "",
      };
      const res = await GoogleMapApi.getRegion(obj);
      const { success, result } = res;
      if (success) {
        // If you need to handle region data...
        const data = result
          .filter(
            (el: any) =>
              ![
                "ON_HOLD",
                "IT",
                "QC",
                "Store",
                "Service",
                "Refurbished",
              ].includes(el.value)
          )
          .filter((el: any) => el.location.image_url !== null)
          .map((el: any) => ({
            ...el,
            origin_label: el.label,
            label: cutString(el.label),
          }));
        // do something with data if needed
      }
    } catch (error: any) {
      swalError(error.message);
    }
  };

  const onSearch = (list: any[]) => {
    if (!valueSearch) return list;
    const searchValue = valueSearch.toLowerCase();
    return list.filter((el) => el.value.toLowerCase().includes(searchValue));
  };

  // Called when the map is loaded
  const onMapLoad = (map: google.maps.Map) => {
    setMapRef(map);
  };

  // Auto-fit bounds whenever markers change or mapRef changes
  // useEffect(() => {
  //   if (mapRef && markers.length > 0) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     markers.forEach(({ lat, lng }) => {
  //       bounds.extend({ lat, lng });
  //     });
  //     mapRef.fitBounds(bounds);
  //   }
  // }, [mapRef, markers]);

  // Translate branch name to TH
  const translateBranchMarkerName = (value: string) => {
    if (value.includes("Apple House")) {
      const cut = value.split("Apple House ");
      return `สาขา ${cut[1]}`;
    } else if (value.includes("Future Park")) {
      const cut = value.split("Future Park ");
      return `สาขา ${cut[1]}`;
    }
    return value;
  };

  const handleMarkerClick = (
    id: number,
    lat: number,
    lng: number,
    url: string,
    address: string
  ) => {
    if (mapRef) {
      // Pan to marker
      mapRef.panTo({ lat, lng });
      mapRef.setZoom(14);
      setZoomLevel(14)// optional, if you want to specifically set zoom
    }
    setInfoWindowData({
      id,
      address: translateBranchMarkerName(address),
      location_url: url,
    });
    infoData.current = {
      id,
      address,
    };
    setIsOpen(true);
  };
  useEffect(() => {
    if (markers.length > 0 && mapRef) {
      // เลือก Marker ตัวแรก
      const first = markers[0];
      handleMarkerClick(
        first.index,
        first.lat,
        first.lng,
        first.location_url,
        first.address,
      );
    }
  }, [markers, mapRef]);

  return (
    <Style.Container>
      <Row>
        <Style.HeaderText>ค้นหาสาขาใกล้คุณ</Style.HeaderText>
      </Row>

      <Row style={{ marginTop: 16, marginBottom: 25 }}>
        {/* MAP SECTION */}
        <Col lg={6} xs={12}>
          {/* Mobile Search Input */}
          <InputTextField
            placeholder="ค้นหา"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={valueSearch}
            onchange={(e: any) => setValueSearch(e.target.value)}
            style={{ display: { xs: "inline-flex", md: "none" }, marginBottom: 2 }}
          />

          {/* Render map only when isLoaded is true */}
          {isLoaded && (
            <GoogleMap
              // Make sure your .mapContainer in googleMap.css has a set height
              mapContainerClassName="mapContainer"
              onLoad={onMapLoad}
              options={{ streetViewControl: false }}
              center={{ lat: 13.7206, lng: 100.4936 }}
              zoom={zoomLevel}
            >
              {markers.map(({ address, lat, lng, location_url, index }) => (
                <MarkerF
                  key={index}
                  position={{ lat, lng }}
                  onClick={() => {
                    handleMarkerClick(index, lat, lng, location_url, address);
                  }}
                >
                  {isOpen && infoWindowData.id === index && (
                    <InfoWindowF
                      onCloseClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Link
                        href={infoWindowData.location_url}
                        target="_blank"
                        underline="none"
                        color={colors.black}
                      >
                        <Typography variant="h6">
                          {infoWindowData.address}
                        </Typography>
                      </Link>
                    </InfoWindowF>
                  )}
                </MarkerF>
              ))}
            </GoogleMap>
          )}
        </Col>

        {/* SEARCH LIST SECTION */}
        <Col lg={6} xs={12}>
          {/* Desktop Search Input */}
          <InputTextField
            placeholder="ค้นหา"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={valueSearch}
            onchange={(e: any) => setValueSearch(e.target.value)}
            style={{ display: { xs: "none", md: "inline-flex" }, width: "100%" }}
          />
          <List
            sx={{
              overflow: "auto",
              border: "1px solid #e1e1e1",
              borderRadius: 3,
              marginTop: 2,
              height: "460px",
            }}
          >
            {filteredList.map((el: any) => (
              <ListItemButton
                key={el.index}
                onClick={() =>
                  handleMarkerClick(el.index, el.lat, el.lng, el.location_url, el.address)
                }
              >
                <ListItemText primary={el.title} />
              </ListItemButton>
            ))}
          </List>
        </Col>
      </Row>

      {/*
        If you need to show refinanceCard or other reviews, uncomment below:
      
      <Row>
        <Style.HeaderText>รีวิวบางส่วนจากลูกค้าจริง</Style.HeaderText>
        <Style.HeaderText>
          ที่ใช้บริการไอโฟนแลกเงิน <span style={{ color: colors.themeMainColor }}>Ufriend</span>
        </Style.HeaderText>
      </Row>
      <Row className="pt-2">
        {refinanceCard.map((item: any) => (
          <Col xs={12} md={6} lg={4} className="py-2" key={item.name}>
            <Card sx={{ borderRadius: "10px" }}>
              <CardContent>
                <div className="d-flex align-items-center">
                  <div className="p-2">
                    <Avatar sx={{ bgcolor: colors.gray }}>N</Avatar>
                  </div>
                  <div className="p-2">
                    <Typography variant="h6" fontWeight="bold">
                      {item.name}
                    </Typography>
                    <div>"{item.details}"</div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Col>
        ))}
      </Row>
      */}
    </Style.Container>
  );
}
